.universities-section {
  width: 100%;
  margin: 40px auto;
  .carousel {
    display: grid;
    place-items: center;
  }

  .slider {
    margin: auto;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
  }

  .slidetrack {
    display: flex;
    gap: 2rem;
    width: calc(250px * 18);
    animation: scroll 40s linear infinite;
    animation-iteration-count: infinite;
    animation-delay: -2s;
  }

  .slidetrack:hover {
    animation-play-state: paused;
  }

  .slide {
    width: 210px;
    object-fit: contain;
    display: flex;
    align-items: center;
    perspective: 100px;
    gap: 40px;
    min-width: 150px !important;
  }

  .slide a {
    width: 100%;
    height: 100%;
    transform: scale(0.8);
    transition: transform 1s;
  }

  .slide a:hover {
    transform: scale(1);
  }

  // .slider::before,
  // .slider::after {
  //   background: linear-gradient(
  //     to right,
  //     #ffffff 0%,
  //     rgba(255, 255, 255, 0) 100%
  //   );
  //   content: "";
  //   height: 100%;
  //   position: absolute;
  //   width: 15%;
  //   z-index: 2;
  // }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  .rowsContainer {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9));
  }
}
