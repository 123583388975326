.assessment-container {
  margin: 80px auto;
}

.circle-one {
  position: relative;
  width: 525.546px;
  height: 524.692px;
  // transform: rotate(-33deg);
  flex-shrink: 0;
  border-radius: 437.25px;
  border: 0.854px solid #8d61e7;
  .clarke-img {
    position: absolute;
    width: 115px;
    height: 115px;
    top: 90px;
    left: -35px;
    border-radius: 50%;
    overflow: hidden;
  }
  .clarke-text {
    position: absolute;
    top: 180px;
    left: -60px;
    color: #000;
    z-index: 4;
    display: flex;
    width: 170.57px;
    height: 44.331px;
    padding: 8.083px 10.53px 8.374px 9.044px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6.559px;
    border: 0.854px solid #d8d8d8;
    background: #fff;
    box-shadow: 0px 0px 40.992px 0px rgba(0, 0, 0, 0.06);
    // font-family: Poppins;
    font-size: 12.298px;
    font-style: normal;
    font-weight: 500;
    line-height: 13.937px;
  }
  .usa-flag {
    position: absolute;
    top: 25px;
    left: 115px;
  }
  .arnav-text {
    position: absolute;
    bottom: 35px;
    left: 50px;
    display: flex;
    width: 170.57px;
    height: 44.331px;
    padding: 8.075px 10.871px 8.382px 9.052px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6.559px;
    border: 0.854px solid #d8d8d8;
    background: #fff;
    box-shadow: 0px 0px 40.992px 0px rgba(0, 0, 0, 0.06);
    color: #000;
    // font-family: Poppins;
    font-size: 12.298px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.937px;
  }
}

.circle-two {
  position: relative;
  width: 419.82px;
  height: 419.82px;
  flex-shrink: 0;
  border-radius: 209.914px;
  border: 0.854px solid #debdff;
  margin: 20px auto;
  .uk-flag {
    position: absolute;
    top: 40px;
    right: 68px;
    overflow: hidden;
    width: 14.749px;
    height: 14.749px;
    flex-shrink: 0;
    border-radius: 7.379px;
  }
  .canada-flag {
    position: absolute;
    bottom: 18px;
    right: 100px;
    overflow: hidden;
    width: 14.749px;
    height: 14.749px;
    flex-shrink: 0;
    border-radius: 7.379px;
  }
  .indian-flag {
    width: 32.785px;
    height: 32.785px;
    overflow: hidden;
    flex-shrink: 0;
    position: absolute;
    left: 17px;
    top: 225px;
    z-index: 8;
  }
}

.circle-three {
  position: relative;
  display: flex;
  width: 360.723px;
  height: 360.723px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 180.366px;
  background: #ffefe0;
  margin: 29px auto;
  overflow: hidden;
}

// animation styles starts here
.career-animation {
  position: relative;
}

.jss-parent {
  position: absolute;
  top: -60px;
  right: 160px;
}

.jss28 {
  top: 7em;
  right: 2em;
  width: 22em;
  height: 22em;
  overflow: hidden;
  background: #ffefe0;
}
.jss27 {
  position: absolute;
  border-radius: 50%;
}
.jss29 {
  width: 100%;
  position: absolute;
  aspect-ratio: 1/1;
}
.jss30 {
  top: 5.25em;
  right: 0.25em;
  width: 25.5em;
  border: 1px solid #debdff;
  height: 25.5em;
  animation: jss52 100s linear infinite;
}
.jss44 {
  top: 3.5em;
  right: 3em;
  width: 0.9em;
  height: 0.9em;
  overflow: hidden;
  animation: jss53 100s linear infinite;
  background-size: cover;
  background-image: url(https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png?20151118161041);
}
.jss45 {
  top: 23.4em;
  right: 6em;
  width: 1.1em;
  height: 1.1em;
  overflow: hidden;
  animation: jss53 100s linear infinite;
  background-size: contain;
  background-image: url(https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/300px-Flag_of_France.svg.png?20151118161041);
}
.jss31 {
  top: 2em;
  right: -3em;
  width: 32em;
  border: 1px solid #debdff;
  height: 32em;
  animation: jss52 100s linear infinite;
}
.jss43 {
  top: 1.8em;
  right: 24em;
  width: 1.2em;
  height: 1.2em;
  overflow: hidden;
  animation: jss53 100s linear infinite;
  background-size: cover;
  background-image: url(https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/800px-Flag_of_the_United_States.svg.png?20151118161041);
}
.jss33 {
  top: 7.5em;
  right: 24em;
  width: 4em;
  height: 4em;
  overflow: hidden;
  background: #ffefe0;
}
.jss42 {
  top: 19em;
  right: 22.7em;
  width: 2em;
  height: 2em;
  overflow: hidden;
}

.span42 {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .india-logo {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
}
.jss35 {
  width: 100%;
  position: absolute;
  aspect-ratio: 1/1;
  .lady-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
}
.jss37 {
  top: 12em;
  right: 21em;
}
.jss36 {
  width: 10.3em;
  border: 0.5px solid #d8d8d8;
  height: 2.6em;
  padding: 0rem 0.5em;
  position: absolute;
  box-shadow: 0em 0em 2.5em rgba(0, 0, 0, 0.06);
  line-height: 0.85em;
  border-radius: 0.4em;
  background-color: #ffffff;
}
.jss27 {
  position: absolute;
  border-radius: 50%;
}
.jss38 {
  top: 29em;
  right: 15.5em;
}
.jss39 {
  width: 0.5em;
  height: 0.5em;
  background: #8d61e7;
}
.jss36 p {
  margin: 0.6em 0em;
  font-size: 0.75em;
}
.jss40 {
  top: 14.4em;
  right: 28.3em;
  z-index: 10;
}
.jss41 {
  top: 236px;
  right: -47px;
  z-index: 10;
}
@keyframes jss52 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.arnav-container {
  position: absolute;
  top: 57px;
  right: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.arnav-img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
}

.arnav-text {
  margin-top: 10px;
  color: #000;
  width: 170.57px;
  padding: 8.075px 10.871px;
  border-radius: 6.559px;
  border: 0.854px solid #d8d8d8;
  background: #fff;
  box-shadow: 0px 0px 40.992px 0px rgba(0, 0, 0, 0.06);
  font-size: 12.298px;
  font-weight: 400;
  line-height: 13.937px;
}
