.tab-container {
  position: relative;
  display: inline-flex; /* Make the container only as wide as its contents */

  border-bottom: 2px solid #eaeaea; /* The line */
}
.ExploreMore {
  display: flex;
  justify-content: center;
  align-items: center;
  .swiper-wrapper {
    padding-bottom: 40px;
  }
}
