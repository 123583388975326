.home-page {
  .arc-div {
    width: 100%;
    height: 120px;
    overflow: hidden;
    position: relative;

    &::before {
      content: "";
      width: 140%;
      padding-bottom: 50%;
      background-color: #eaf8fb;
      position: absolute;
      top: 0;
      left: -20%;
      border-radius: 50%;
    }
  }

  .arc-div.rev-arc {
    &::before {
      top: auto;
      bottom: 0;
      padding-top: 50%;
      padding-bottom: 0;
    }
  }

  section.mission-vision {
    .card {
      perspective: 1000px;

      .content {
        transition: transform 1s;
        transform-style: preserve-3d;
        position: relative;
        min-height: 400px;

        .front,
        .back {
          position: absolute;
          backface-visibility: hidden;
          min-height: 400px;
        }

        .back {
          transform: rotateY(180deg);
        }

        &::after {
          content: "";
          display: block;
          clear: both;
        }
      }

      &:hover .content {
        transform: rotateY(180deg);
        transition: transform 0.5s;
      }
    }
  }

  .whyDreamsCard {
    display: block;
    position: absolute;
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-transition: opacity 0.6s cubic-bezier(0.2, 0.8, 0.4, 1),
      -webkit-transform 0.6s cubic-bezier(0.2, 0.8, 0.4, 1);
    transition: opacity 0.6s cubic-bezier(0.2, 0.8, 0.4, 1),
      -webkit-transform 0.6s cubic-bezier(0.2, 0.8, 0.4, 1);
    transition: transform 0.6s cubic-bezier(0.2, 0.8, 0.4, 1),
      opacity 0.6s cubic-bezier(0.2, 0.8, 0.4, 1);
    transition: transform 0.6s cubic-bezier(0.2, 0.8, 0.4, 1),
      opacity 0.6s cubic-bezier(0.2, 0.8, 0.4, 1),
      -webkit-transform 0.6s cubic-bezier(0.2, 0.8, 0.4, 1);

    &.active {
      position: relative;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
    }

    &.in-active {
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
      opacity: 0;
    }
  }

  // .whydreamsSections .pinned-cont {
  // 	transition: background-color 0.5s ease-in-out;
  // }

  .info-card {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: transform 0.5s ease-in-out;
    }

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.895)
      );
      transition: background-color 0.5s ease-in-out;
    }

    .info {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
      transition: transform 0.5s ease-in-out;
      color: white;
      padding: 1rem;
    }

    .progress-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.4rem;
    }

    .fill {
      width: 20%;
      height: 100%;
      background-color: #ffc700;
      transition: width 0.5s ease-in-out;
    }
  }

  .ourInvestors {
    .info-card .info {
      transform: translateY(calc(100% - 5.5rem));

      @media screen and (max-width: 640px) {
        transform: translateY(calc(100% - 5rem));
      }
    }
  }

  .ourTeam {
    .info-card .info {
      transform: translateY(calc(100% - 5.5rem));

      @media screen and (max-width: 640px) {
        transform: translateY(calc(100% - 5rem));
      }
    }
  }

  .blogs-section {
    .info-card .info {
      transform: translateY(calc(100% - 5.5rem));

      @media screen and (max-width: 640px) {
        transform: translateY(calc(100% - 5.25rem));
      }
    }

    .container {
      .grid-template {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.5rem;
      }
    }
  }

  .info-card:hover .image {
    transform: scale(1.2);
  }

  .info-card:hover .overlay {
    background-color: #00000083;
  }

  .info-card:hover .info {
    transform: translateY(0);
  }

  .info-card:hover .fill {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    .blogs-section .container .grid-template {
      grid-template-columns: 1fr;
    }
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    .blogs-section .container .grid-template {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .blogs-section .container .grid-template {
      grid-template-columns: 1fr 1fr;
    }
  }

  .mission-vision-card {
    & {
      --cover-timing: 0.5s;
      --cover-ease: cubic-bezier(0.66, 0.08, 0.19, 0.97);
      --cover-stagger: 0.15s;

      --text-timing: 0.75s;
      --text-stagger: 0.015s;
      --text-ease: cubic-bezier(0.38, 0.26, 0.05, 1.07);

      --title-stagger: 0.05s;
      --highlight: #003fbc;
    }

    position: relative;
    overflow: hidden;
    aspect-ratio: 9/12;
    display: flex;
    flex-direction: column;
    color: white;
    border-radius: 7px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 5vw 6vw -8vw,
      rgba(255, 255, 255, 0) 0 4.5vw 5vw -6vw,
      rgba(50, 50, 80, 0.5) 0px 4vw 8vw -2vw,
      rgba(0, 0, 0, 0.8) 0px 4vw 5vw -3vw;
    transition: box-shadow 1s var(--cover-ease);

    & > * {
      z-index: 2;
    }

    & > img {
      z-index: 0;
      transition: all 0.8s cubic-bezier(0.66, 0.08, 0.19, 0.97);
    }

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 50%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      transform-origin: left;
      transform: scaleX(0);
      transition: all var(--cover-timing) var(--cover-ease);
      z-index: 1;
    }

    &::after {
      transition-delay: var(--cover-stagger);
      top: 50%;
    }

    &:hover,
    &:focus {
      box-shadow: white 0 5vw 6vw -9vw, var(--highlight) 0 5.5vw 5vw -7.5vw,
        rgba(50, 50, 80, 0.5) 0px 4vw 8vw -2vw,
        rgba(0, 0, 0, 0.8) 0px 4vw 5vw -3vw;

      &::before,
      &::after {
        transform: scaleX(1);
      }

      & h2 .char,
      & p .word {
        opacity: 1;
        transform: translateY(0);
        color: inherit;
      }

      & h2 .char {
        transition-delay: calc(0.1s + var(--char-index) * var(--title-stagger));
      }

      & p .word {
        transition-delay: calc(0.1s + var(--word-index) * var(--text-stagger));
      }

      & img {
        transform: scale(1.1);
      }
    }

    .text {
      position: absolute;
      inset: 20px;
      top: auto;
    }

    // h2 {
    // 	font-size: 30px;
    // 	font-size: clamp(20px, 4vw, 40px);
    // 	font-weight: 800;
    // 	margin-bottom: 0.2em;
    // }

    // p {
    // 	font-size: 12px;
    // 	font-size: clamp(10px, 1.25vw, 14px);
    // 	line-height: 1.4;
    // 	text-align: justify;
    // 	margin-top: 0.2em;
    // 	margin-bottom: 0;
    // }

    h2 .char,
    p .word {
      color: var(--highlight);
      display: inline-block;
      opacity: 0;
      position: relative;
      transform: translateY(20px);
      transition-property: transform, opacity, color;
      transition-timing-function: var(--text-ease);
      transition-duration: var(--text-timing), var(--text-timing),
        calc(var(--text-timing) * 2);
    }

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 7px;
    }

    width: 90vw;
    max-width: 300px;
  }
}

// Styling Blogs data
.all-blogs {
  display: grid;
  gap: 10px;
}

@media (min-width: 1025px) {
  .all-blogs {
    grid-template-columns: 1fr 1fr 1fr;
    height: 100vh;
  }

  .blog-one {
    grid-row: span 2;
  }

  .blog-two,
  .blog-three,
  .blog-four,
  .blog-five {
    height: 50%;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .blog-one {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(100px, auto);
  }

  .blog-one {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  .blog-two {
    grid-column: 1;
    grid-row: 3;
  }

  .blog-three {
    grid-column: 2;
    grid-row: 1;
  }

  .blog-four {
    grid-column: 2;
    grid-row: 2;
  }

  .blog-five {
    grid-column: 2;
    grid-row: 3;
  }
}

@media (max-width: 766px) {
  .all-blogs {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(100px, auto);
  }
}
