.downloads-title {
  color: #383838;
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
}
.exam-downloads {
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.download-paper {
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.download-jee {
  font-size: 13px;
  color: #262626;
  font-family: Roboto;

  font-style: normal;
  font-weight: 700;
}
