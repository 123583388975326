.carrerInCourse {
  .swiper-pagination {
    position: relative;
    top: -20px;
    .swiper-pagination-bullet {
      position: relative;
      z-index: 10;
    }
  }
}
