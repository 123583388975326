.explore-tilte {
  color: #383838;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px; /* 150% */
}
.slidetrack {
  display: flex;
  width: calc(280px * 18);
  animation: scroll 20s linear infinite !important;
  animation-iteration-count: infinite;
  animation-delay: -2s;
}

.slidetrack:hover {
  animation-play-state: paused !important;
}
@media (min-width: 321px) and (max-width: 450px) {
  .explore-tilte {
    color: var(--Dark, #000);
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 137.5% */
  }
  .explore-card {
    width: 284.245px;
    border-radius: 17.266px;
    border: 1.079px solid var(--Gray-5, #e0e0e0);
    background: #fff;
  }
}
