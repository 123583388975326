@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #113cc0;
  --secondPrimary-color: #173cba;
  --secondary-color: #00c798;
  --paragraph-color: #292929;
  --heading-color: #113cc0;
  --blue: #173cba;
  --light-green: #00cc99;
  --light-pink: #ff7bcd;
  --body-colo: #383838;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
}

/* Global Button Styles */
.no-scroll {
  overflow: hidden;
}

.green-color {
  border: 1px solid #00c798;
  color: #00c798;
}

.green-bg {
  background-color: #00c798;
  color: #fff;
}

.blue-color {
  border: 1px solid #173cba;
  color: #173cba;
}

.white-color {
  border: 1px solid white;
  color: white;
}

.blue-text {
  color: #113cc0;
}

.blue-bg {
  background-color: #113cc0;
  color: #fff;
}

.button-styles {
  display: flex;
  padding: 14px 34px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.green-arrow-button {
  cursor: pointer;
  position: relative;
}

.green-arrow-icon {
  display: inline-block;
  transition: transform 0.3s ease-out;
}

.green-arrow-button:hover .green-arrow-icon {
  transform: translateX(6px);
}

@media (max-width: 768px) {
  .button-styles {
    padding: 10px 16px;
  }
}

.button-styles:hover {
  border-radius: 0;
  background-color: #fff;
  color: #fff;
  transition: 0.3s;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.29);
}

.green-color:hover {
  background: #00c798;
}

.green-bg:hover {
  background: #00c798;
}

.blue-color:hover {
  background: #113cc0;
}

.white-color:hover {
  background: #113cc0;
}

@keyframes cbSlideOut {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10%);
  }
}

.cd-btn {
  background-color: #113cc0;
  color: #fff;
  border: none;
  border-left: 0.8px solid #fff;
  border-right: 0.8px solid #fff;
}

.active-btn {
  color: #fff;
  background-color: #020842;
  border: none;
  border-left: 0.8px solid #fff;
  border-right: 0.8px solid #fff;
}

.grey-color {
  color: #f4f7fb;
}

.grey-bg {
  background-color: #f4f7fb;
}

.gray-1 {
  color: #333;
}

.job-role-tab {
  border-radius: 18px;
  background: #fff;

  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
}

.active-tab {
  border-radius: 14px;
  border: 2px solid #113cc0;
  background: #fff;
  box-shadow: 0px 6px 16px 0px rgba(0, 68, 124, 0.16);
}

.chart-section {
  width: 813px;
  height: auto;
  padding: 24px;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
}

.overview-tab-animation .jss-parent {
  position: absolute;
  top: -60px;
  right: 50px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.mentor-card2 {
  position: relative;
  width: 282px;
  height: 380px !important;
  height: auto;
  flex-shrink: 0;
}

.mentor-card2 img {
  width: 100%;
}

.mentor-card2 .mentor-details {
  width: 246px;
  margin: 0 auto;
  padding: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 54%);
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.mentor-details h3 {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mentor-details p {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.mentor-details span {
  color: #113cc0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mentor-detailssvg {
  display: inline-block;
  margin-left: 6px;
}

.jsonContentUI p,
.jsonContentUI li {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: #231f40;
}

.jsonContentUIWhite p,
.jsonContentUIWhite li {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.hoverIconanimate::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background-color: #2575fc;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  visibility: hidden;
}

.historyContent:hover .hoverIconanimate:before {
  background-color: #113cc0;
  opacity: 1;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  visibility: visible;
}

.historyContent .historyCon::before {
  position: absolute;
  content: "";
  top: 50%;
  right: -20px;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-style: solid;
  border-width: 20px;
  z-index: 9;
  border-color: #f1f1f1 #f1f1f1 transparent transparent;
}

.historyContent:hover .historyCon::before {
  border-color: #113cc0;
}

.NotableAlumniContent .MuiTabs-flexContainer {
  display: flex;
  gap: 16px;

  .button-tab {
    border-bottom: 2px solid #c5c5c5;
  }
}

.chatOptions {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  position: absolute;
  display: flex;
}

.navigationList .courseNavigationMenu {
  color: #383838;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-transform: capitalize;
  padding: 18px;
}

.stickyNavigation {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.fade-in {
  animation: fadeInAnimation 1s;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tabNavigationBar {
  border-radius: 5px;
  background: linear-gradient(180deg, #173cba 0%, #06afa9 100%);
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

ul {
  list-style-type: disc;
}

.universities-mentors-swiper {
  margin: 0 50px !important;
}

.middle-boder:before {
  content: "";
  position: absolute;
  border-left: 3px solid #afafaf;
  width: 3px;
  height: 100%;
  left: 50%;
  text-align: center;
}

/* Thin Scrollbar Style */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 6px; /* Height of the scrollbar (for horizontal scrollbars) */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar color */
  border-radius: 10px; /* Rounded scrollbar */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}

.explore-button {
  background-color: #173cba;
  padding: 14px 20px;
  margin-top: 20px;
  color: #ffffff;
  display: table;
  border-radius: 50px;
  &:hover {
    background-color: #00c798;
  }
}
