@keyframes scrollFade {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.logo-container {
  display: flex;
  overflow: hidden;
  position: relative;
}

.slidetrack {
  display: flex;
  transition: transform 2s ease-in-out;
  will-change: transform;
}

.logo-item {
  height: 85px;
  width: 300px;
  display: flex;
  align-items: center;
  perspective: 100px;
  gap: 30px;
  max-width: 300px;
}

.logo-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 2s ease-in-out;
}

.logo-item img:hover {
  transform: scale(1.1);
}
