$color_1: var(--primary_color);
$color_2: #ccc;
$color_3: #ff0000;
$background-color_1: #fff;

.input-wrapp {
	.input-container {
		border: 1px solid $color_2;
		display: flex;
		align-items: center;
		background-color: $background-color_1;
		margin: 0.5rem 0;
		width: 100%;
		padding: 0 1rem;
		border-radius: 50px;
		input {
			border: none;
			box-shadow: none;
			outline: none;
			color: $color_1;
			font-size: 16px;
			flex-grow: 1;
			padding: 1.2rem 0;
			background-color: $background-color_1;
			border-radius: 50px;
			&::placeholder {
				font-weight: lighter;
			}
			&:-webkit-autofill {
				-webkit-background-clip: text;
				&:hover {
					-webkit-background-clip: text;
				}
				&:focus {
					-webkit-background-clip: text;
				}
				&:active {
					-webkit-background-clip: text;
				}
			}
		}
		&:has(input:focus) {
			border: 1px solid $color_2;
		}
		
		.input-icon {
			font-size: 20px;
			color: $color_1;
			height: 20px;
			padding-right: 5px;
		}
	}
	width: 100%;
	.input-label {
		display: block;
		text-align: left;
	}
	.error-helperText {
		color: $color_3;
	}
	.input-container.error {
		border: 1px solid $color_3;
		background-color: $background-color_1;
		&:has(input:focus) {
			border: 1px solid $color_3;
			background-color: $background-color_1;
		}
	}
}
