.emi-calculator {
  input[type="range"] {
    appearance: none;
    border-radius: 9999px;
    background-color: #173cba;
    height: 0.5rem;
  }

  /* WebKit browsers (Chrome, Safari, Edge) */
  input[type="range"]::-webkit-slider-runnable-track {
    height: 0.5rem;
    border-radius: 9999px;
  }

  input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    border-radius: 50%;
    background: #f8d547;
  }

  /* For Firefox */
  input[type="range"]::-moz-range-track {
    height: 0.5rem;
    background-color: #173cba;
    border-radius: 9999px;
  }

  input[type="range"]::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    border-radius: 50%;
    background: #f8d547;
    cursor: pointer;
  }

  input[type="range"]::-moz-range-progress {
    height: 0.5rem;
    background-color: #00cc99; /* Filled progress color */
    height: 8px;
    border-radius: 9999px;
  }

  /* For IE */
  input[type="range"]::-ms-track {
    height: 0.5rem;
    background: transparent;
    border-color: transparent;
    color: transparent;
    width: 100%;
    height: 8px;
  }

  input[type="range"]::-ms-fill-lower {
    height: 0.5rem;
    background-color: #00cc99; /* Filled progress color */
    border-radius: 9999px;
  }

  input[type="range"]::-ms-fill-upper {
    height: 0.5rem;
    background-color: #173cba; /* Default background */
    border-radius: 9999px;
  }
}
