.container-section {
  width: 1360px;
  margin: 40px auto;
  display: flex;
  flex-direction: row;
}
.container-section {
  width: 1360px;
  margin: 0 auto;
}
// .college-card {
//   position: relative;
//   overflow: hidden;
// }

// .content-section {
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 10;
// }
// .card-content {
//   background-color: #fff;
//   opacity: 1;
//   transition: opacity 0.3s ease-in-out;
//   transform: translateY(20px);
// }
.dc-heading {
  color: #231f40;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.college-info {
  display: flex;
}

.college-info-card {
  width: 430px;
  height: auto;
  flex-shrink: 0;
  position: relative;
  img {
    border-radius: 16px;
  }
}

.content-section {
  width: 430px;
  height: 130px;
  flex-shrink: 0;
  background-color: #fff;
}

.name-and-icon {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  .clg-name {
    color: #333;
    font-size: 18px;
    font-style: normal;
    align-self: center;
    font-weight: 600;
    line-height: normal;
  }
}

.button-section {
  display: flex;
  padding: 11px 24px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  .virtual-campus-button {
    display: flex;
    padding: 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
    border-radius: 50px;
    background: #113cc0;
  }
  .apply-now-button {
    display: flex;
    padding: 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
    border-radius: 50px;
    background: #00c798;
  }
}
/* ArrowButton.css */

.arrow-button {
  background: none;
  // border: none;
  cursor: pointer;
  // padding: 10px;
  position: relative;
  // overflow: hidden;
}

.arrow-icon {
  display: inline-block;
  transition: transform 0.3s ease-out;
}

.arrow-button:hover .arrow-icon {
  transform: translateX(6px); /* Adjust distance of movement */
}
