.preference {
  background-color: #173cba;
}
.para-pref {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
}
.parasub-pref {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  margin-top: 8px;
}
.button-pref {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 8px 16px;
  width: 100%; // Ensures buttons fill grid cell
  height: 50px; // Consistent height for buttons
  transition: background-color 0.2s;
}
.button-pref:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.number-in {
  border-radius: 50px;
  border: 1px solid #fff;
  padding: 8px 16px;
}
