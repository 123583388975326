@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

.campus_life_heading {
  font-size: 32px;
  font-weight: 600;
  color: #0a0909;
  font-family: "Poppins", sans-serif;
}

.campus_life_subheading {
  font-size: 14px;
  font-weight: 300;
  color: #aba9a9;
  font-family: "Poppins", sans-serif;
}

.foo-item {
  position: relative;
  background-color: #383e5c;
  width: 100%;
  border-radius: 0 10px 10px 0;
}

.marker {
  $marker-height: 37px;
  $fold-height: 16px;
  $fold-width: 12px;
  position: absolute;
  top: 0;
  left: -5px;
  display: inline-block;
  height: 200px;
  /* padding: 12px 0 12px 18px; */
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #c70b0b;
  border-top-left-radius: 8px;
  width: 10px;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 100%;
    top: 20px;
    width: 220px;
    height: 27px;
    border-style: solid;
    border-width: 0px 0 0.5px 190px;
    border-color: transparent transparent transparent #c70b0b;
  }

  .marker__fold {
    position: absolute;
    top: 100%;
    left: 0;
    width: $fold-width;
    height: $fold-height / 2;
    background-color: #000;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: $fold-height;
      background: linear-gradient(to right, #333334 0%, #1a1718 100%);
      border-radius: $fold-width * 2 0 0 $fold-width * 2;
    }
  }
}

.heading_position {
  text-transform: uppercase;
  position: absolute;
  top: 6%;
  left: 15px;
  font-size: 15px;
  font-weight: 600;
  color: #ffff;
  font-family: "Poppins", sans-serif;
  text-wrap: nowrap;
}

.info_text {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.image-slider {
  position: relative;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 450px;
  height: 300px;
  border-radius: 12px;
}

.left {
  position: absolute;
  left: 60%;
  top: 20%;
  font-size: 2rem;
  color: #ffffff;
  z-index: 5;
  cursor: pointer;
  user-select: none;
}

.left:hover {
  color: #113cc0;
  transform: scale(1.2);
}

.right:hover {
  color: #113cc0;
  transform: scale(1.2);
}

.right {
  position: absolute;
  right: 0%;
  top: 20%;
  font-size: 2rem;
  color: #ffffff;
  z-index: 5;
  cursor: pointer;
  user-select: none;
}

.currentSlide {
  transition-duration: 0.5s ease;
  opacity: 0;
}

.currentSlide.active {
  opacity: 1;
  transition-duration: 0.5s;
  transform: rotate(0deg);
}

.heading_campus {
  color: #0a0909;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.position_of_col {
  position: absolute;
  translate: transform(-50%);
  top: 30%;
  left: -3%;
  height: 50vh;
  background: red;
}

.sports_text_heading {
  position: relative;
  text-align: center;
  font-family: "Urbanist", sans-serif;
  color: #0a0909;
  font-weight: 600;
  font-size: 32px;
  text-align: center;
}

.sports_text_heading::before {
  content: "";
  position: absolute;
  top: 100%;
  // left: 0;
  width: 6%;
  /* Adjust width as needed */
  transform: translate(100%);
  border-bottom: 3px solid #0c4da2;
  /* Adjust color and style as needed */
}

.sports_text_heading::after {
  content: "";
  display: inline-block;
  margin: 0 5px;
  /* Adjust as needed */
}

.sports_text_heading::before,
.sports_text_heading::after {
  width: 6%;
  /* Adjust width as needed */
  height: 0;
}

.sports_text_subheading {
  color: #313131;
  text-align: justify;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.sports_text_heading1 {
  position: relative;
  text-align: center;
  text-align: justify;
  font-family: "Urbanist", sans-serif;
  color: #0a0909;
  font-weight: 600;
  font-size: 32px;
  text-align: center;
}

.sports_text_heading1::before {
  content: "";
  position: absolute;
  top: 105%;
  left: 40%;
  width: 6%;
  /* Adjust width as needed */
  transform: translate(100%);
  border-bottom: 3px solid #0c4da2;
  /* Adjust color and style as needed */
}

.sports_text_heading1::after {
  content: "";
  display: inline-block;
  margin: 0 5px;
  /* Adjust as needed */
}

.sports_text_heading1::before,
.sports_text_heading1::after {
  // width: 6%; /* Adjust width as needed */
  height: 0;
}

.sports_text_subheading {
  color: #313131;
  text-align: justify;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Creates four equal columns */
  gap: 20px;
  /* Adjust the gap between cards */
}

.card_container {
  border: 1px solid #0c4da2;
  border-radius: 10px;
}

.game_courts {
  font-family: "Urbanist", sans-serif;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  line-height: 26px;
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.bg_sports_img_container {
  position: relative;
  width: 100%;
  height: 400px;
  /* Adjust the height as needed */
}

.bg_sports_img {
  width: 100%;
  height: 100%;
  background-image: url("../Images/sports_bg.jpg");
  background-size: cover;
  background-position: center;
}

.bg_sports_img_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity as needed */
}

.heading_sport_achievement {
  position: relative;
  text-align: center;
  z-index: 999;
  font-size: 32px;
  color: #ffffff;
  font-weight: 600;
  font-family: "Urbanist", sans-serif;
}

.all_sports {
  position: relative;
  z-index: 9999;
  overflow: hidden;
  height: 3rem;
  padding: 0 2rem;
  border-radius: 10px;
  background: #113cc0;
  background-size: 400%;
  color: #fff;
  border: none;
  cursor: pointer;
}

.all_sports:hover::before {
  transform: scaleX(1);
}

.all_sports-content {
  position: relative;
  z-index: 1;
}

.all_sports::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: rgb(91, 91, 37);
  transition: all 0.475s;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
  /* Adjust transition duration as needed */
}

.accordion {
  border-radius: 3px;
  background: #f1f1f1;
}

.accordion-visible {
  background: #7177ca;
  width: 100%;
  color: #fff;
  cursor: pointer;
  border: 1px solid #474d9b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.accordion-visible span {
  font-size: 20px;
}

.accordion-visible img {
  width: 20px;
  transform: rotate(-90deg);
  transition: transform 0.2s ease-in-out;
}

.accordion-visible img.active {
  transform: rotate(0deg);
}

.accordion-visible h2 {
  font-size: 20px;
}

.accordion-toggle {
  height: 0px;
  font-size: 18px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, height 0.3s 0.3s ease-in-out;
  color: #333;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.animated {
  opacity: 1;
  transition: height 0.3s ease-in-out, opacity 0.3s 0.3s ease-in-out;
}

.accordion-toggle p {
  margin: 0;
  padding: 22px 15px;
  pointer-events: none;
  line-height: 1.3;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);

figure.snip1390 {
  font-family: "Roboto", Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  border-radius: 12px;
  color: #000000;
  text-align: center;
  font-size: 16px;
  background-color: #113cc0;
  padding: 30px;
  background-image: linear-gradient(
    -25deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  transition: transform 0.2s;
}

figure.snip1390:hover {
  transform: scale(1.1);
}

figure.snip1390 *,
figure.snip1390 *:before,
figure.snip1390 *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

figure.snip1390 figcaption {
  width: 100%;
}

figure.snip1390 h2,
figure.snip1390 h4,
figure.snip1390 blockquote {
  margin: 0;
}

figure.snip1390 h2,
figure.snip1390 h4 {
  font-weight: 300;
}

figure.snip1390 h2 {
  color: #ffffff;
}

figure.snip1390 h4 {
  color: #a6a6a6;
}

figure.snip1390 blockquote {
  font-size: 1em;
  padding: 45px 20px 40px 50px;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3);
  text-align: left;
  position: relative;
}

figure.snip1390 blockquote:before {
  font-family: "FontAwesome";
  content: "\201C";
  position: absolute;
  font-size: 70px;
  opacity: 0.25;
  font-style: normal;
  top: 0px;
  left: 20px;
}

figure.snip1390 .profile {
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
  border: solid 5px #a6a57a;
}

.review_card_positions {
  margin-top: -15rem;
}

.item {
  margin-bottom: 5px;
}

.title {
  color: #1f355e;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in all;
}

.title:hover {
  color: #ffffff;
  cursor: pointer;
  background: #0c4da2;
}

// .content {
//   color: #8b7f75;
//   max-height: 0;
//   overflow: hidden;
//   transition: all 0.5s cubic-bezier(0, 1, 0, 1);
// }

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.accordion_title {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #1f355e;
  font-weight: 600;
}

.accordion_content {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: #1f355e;
  font-weight: 400;
}

.banner_review_content_position {
  width: 100%;
  z-index: 9999;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 9%;
}
.banner_review_content {
  color: #ffffff;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
}

.messageBox {
  width: fit-content;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 0 15px;
  border-radius: 50px;
}

.fileUploadWrapper {
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
}

#file {
  display: none;
}
.fileUploadWrapper label {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.fileUploadWrapper label svg {
  height: 18px;
}
.fileUploadWrapper label svg path {
  transition: all 0.3s;
}
.fileUploadWrapper label svg circle {
  transition: all 0.3s;
}
.fileUploadWrapper label:hover svg path {
  stroke: #fff;
}
.fileUploadWrapper label:hover svg circle {
  stroke: #fff;
  fill: #3c3c3c;
}
.fileUploadWrapper label:hover .tooltip {
  display: block;
  opacity: 1;
}
.tooltip {
  position: absolute;
  top: -40px;
  display: none;
  opacity: 0;
  color: white;
  font-size: 10px;
  text-wrap: nowrap;
  background-color: #113cc0;
  padding: 6px 10px;

  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.596);
  transition: all 0.3s;
}
#messageInput {
  width: 200px;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  padding-left: 10px;
  color: rgb(70, 68, 68);
  font-family: "Poppins", sans-serif;
}
#messageInput:focus ~ #sendButton svg path,
#messageInput:valid ~ #sendButton svg path {
  fill: #3c3c3c;
  stroke: white;
}

#sendButton {
  width: fit-content;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}
#sendButton svg {
  height: 18px;
  transition: all 0.3s;
}
#sendButton svg path {
  transition: all 0.3s;
}
#sendButton:hover svg path {
  fill: #3c3c3c;
  stroke: white;
}

.college-history {
  .swiper-button-disabled {
    opacity: 0.2;
  }
}

.bulletin-board-list {
  padding: 10px;
  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    padding-bottom: 10px;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 1.2em;
    border-top: 1px solid #dad5d5;
    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      text-align: left;
    }
  }
}
.collageOveriew {
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: #383838;
    margin: 0;
  }
  ul {
    padding-left: 20px;
  }
}
.collegeHighlights {
  // margin: 20px 0;
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: #383838;
    margin: 20px 0;
  }
  ul {
    padding-left: 20px;
    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: #383838;
    }
  }
  table {
    border: 0.5px solid #383838;
    background: #ffffff;
    @media (max-width: 767px) {
      display: block;
      overflow-y: hidden;
      width: 100%;
    }

    tr {
      th,
      td {
        border: 0.5px solid #383838;
        padding: 10px;
        font-size: 14px;
        text-align: left;
        line-height: 20px;
        color: #383838;
        width: 50%;
      }
    }
    tr td ul {
      padding-left: 20px;
    }
  }
}
.collaborationsContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  h2,
  h3 {
    font-size: 1em;
    font-weight: bold;
  }
  ul {
    padding-left: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: #383838;
  }
  table {
    border: 0.5px solid #383838;
    background: #ffffff;
    @media (max-width: 767px) {
      display: block;
      overflow-y: hidden;
      width: 100%;
    }

    tr {
      th,
      td {
        border: 0.5px solid #383838;
        padding: 10px;
        font-size: 14px;
        text-align: left;
        line-height: 20px;
        color: #383838;
        width: 50%;
      }
    }
    tr td ul {
      padding-left: 20px;
    }
  }
}
.rankingsTab {
  h3,
  h2,
  p span {
    font-size: 20px;
    color: #113cc0;
    font-weight: 600;
  }
  table {
    border: 0.5px solid #383838;
    background: #ffffff;
    width: 100%;

    tr {
      th,
      td {
        border: 0.5px solid #383838;
        padding: 10px;
        font-size: 14px;
        text-align: left;
        line-height: 20px;
        color: #383838;
      }
    }
  }
  ul {
    padding-left: 20px;
  }
}

.CampusFacilitiesList {
  ul {
    padding-left: 20px;
  }
}
