.button-styles-explore {
  display: flex;
  padding: 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.button-styles-explore:hover {
  border-radius: 0;
  color: #fff;
  transition: 0.3s;
  box-shadow: 0 0 8px rgba(73, 50, 50, 0.29);
}
.green-color {
  background-color: #fff;
}
.title-explore {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
}
.green-color:hover {
  background: #00c798;
}
.blue-color {
  background-color: #fff;
}
.blue-color:hover {
  background: #113cc0;
}
.blue-text {
  color: #113cc0;
}

.blue-bg {
  background-color: #113cc0;
  color: #fff;
}

.tab-container-occupation {
  position: relative;
  display: inline-flex; /* Make the container only as wide as its contents */

  border-bottom: 2px solid #eaeaea; /* The line */
}
.ExploreMore-occupation {
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-icon {
  transition: transform 0.2s ease-in-out;
}

.arrow-icon:hover {
  transform: translateX(6px); // Adjust the value as needed
}
