.highPaidCareer {
  padding-bottom: 2rem;
  .swiper-wrapper {
    padding-bottom: 2rem;
  }
}
.know-more {
  background: #173cba;
  // border: none;
  cursor: pointer;
  // padding: 10px;
  position: relative;
  // overflow: hidden;
}

.arrow-icon {
  display: inline-block;
  transition: transform 0.3s ease-out;
}

.know-more:hover .arrow-icon {
  transform: translateX(6px);
}
