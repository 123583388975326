.heading-tools{
    color: #383838;

    text-align: center;
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px;

  }