.featuredCareerSec {
  position: relative;
  &:hover {
    .featuredCareer {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .featuredCareer {
    opacity: 0;
    transform: translateY(0);
    transition: all 0.4s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 10px;
    top: 0;
    left: 0;
    transform: translateY(100%);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
    border-radius: 10px;
  }
  .featuredCareer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: -9;
  }
  .featuredCareerList {
    position: relative;
  }
}
