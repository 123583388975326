/* styles.css (or your preferred stylesheet) */

/* Basic reset */
body,
h1,
p {
  margin: 0;
  padding: 0;
}

/* Center container vertically and horizontally */
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
}

/* Header styles */
.not-found-header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  width: 100%;
}

/* Main content styles */
.not-found-main {
  padding: 20px;
}

// /* Footer styles */
// .not-found-footer {
//   background-color: #333;
//   color: #fff;
//   padding: 10px 0;
//   width: 100%;
//   position: fixed;
//   bottom: 0;
//   text-align: center;
// }
