.head{
    color: #FFF;

text-align: center;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
font-family: "Open Sans";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 125% */

}/* App.css */

.form-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

