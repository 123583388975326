.blog-sample{
    color: #173CBA;

    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.blog-description{
    color: #383838;

    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal
}
.blog-site{
    color: #173CBA;

    text-align: right;
    font-family: "Open Sans";
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}