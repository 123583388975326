.ikigai-mentor-section .swiper-button-prev,
.ikigai-mentor-section .swiper-button-next {
  width: 40px;
  height: 40px;
  display: block !important;
  background-color: #173cba;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px;
}

.ikigai-mentor-section .swiper-button-prev::after,
.ikigai-mentor-section .swiper-button-next::after {
  font-size: 16px;
  color: #ffffff;
}
.top-pricingplan-img {
  max-width: fit-content;
  height: fit-content;
}
