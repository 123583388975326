.college-name{
    color: #383838;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;

}
.ownership{
    color: #7C7C7C;

font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 225% */
}

.goodchance{
    color: #173CBA;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.good{
    color: #0C9;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.course-name{
    color: #383838;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 21px; /* 150% */
}

.fee{
    color: #7C7C7C;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 21px; /* 150% */
}

.goodagain{
    color: #0C9;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 21px; /* 150% */
}
.cutoff{
    color: #7C7C7C;
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 21px; /* 175% */
}

.cutoff-button{
    color: #7C7C7C;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 21px; /* 131.25% */
}

.compare{
    color: #7C7C7C;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 21px; /* 150% */
}