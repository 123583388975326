.dc-card {
  width: 320px;
  height: 340px;
  flex-shrink: 0;
  // margin-bottom: 25px;
  position: relative;
  overflow: hidden;
  .dc-img {
    height: 291px;
    img {
      width: 100%;
    }
  }
  .dc-content {
    background-color: #fff;
    padding: 15px 10px;
    height: 144px;
    position: absolute;
    bottom: -100px;
    h3 {
      color: #333;
      // font-family: Poppins;
      margin-bottom: 10px;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    p {
      color: #828282;
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    button {
      color: #113cc0;
      //   font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      svg {
        display: inline-block;
        margin-left: 6px;
      }
    }
  }
}
.dc-card:hover .dc-content {
  bottom: 0;
  transition: 0.5s ease-in-out;
}

.btn-dc {
  display: inline-flex;
  padding: 14px 34px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  // font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 50px;
}
