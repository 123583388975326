.examDetailsSec {
}
.examDetailsTable,
.examDetailsTabletwo {
  table {
    border: 1px solid #383838;
    background: white;
    width: 100%;
    th {
      text-align: left;
      font-weight: 600;
      color: #383838;
      font-size: 16px;
      line-height: 20px;
      padding: 20px 10px;
      border: 1px solid #383838;
    }
  }
  td {
    text-align: left;
    font-weight: normal;
    color: #383838;
    font-size: 16px;
    line-height: 20px;
    padding: 20px 10px;
    border: 1px solid #383838;
    a {
      color: #007bff; /* Link color */
      text-decoration: none; /* Remove underline */
      font-weight: 500;
      &:hover {
        text-decoration: underline; /* Underline on hover */
      }
    }
  }
    ul {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      font-weight: 500;
    }
  }

.examDetailsTabletwo {
  tbody {
    tr:nth-child(odd) {
      background-color: #eef2ff;
    }
    tr:nth-child(even) {
      background-color: white;
    }
  }
}
.examDetailsList {
  h3 {
    font-size: 16px;
    color: #383838;
    font-weight: 600;
  }
  h2 {
    font-size: 20px;
    color: #173cba;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    color: #383838;
    line-height: 24px;
  }
  ul,
  ol {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    list-style-type: disc;
    li {
      font-size: 16px;
      line-height: 24px;
      color: #383838;
      a {
        color: #007bff;
        text-decoration: underline;
        &:hover {
          color: #0056b3;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .examDetailsTable,
  .examDetailsTabletwo {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: none;
  }
}
