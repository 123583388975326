.card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 32rem;
  height: fit-content;
  padding: 0rem 1rem 1rem 1rem;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;

}
@media (max-width: 32rem) {
  .card {
    width: 345px; /* Width for smaller screens */
  }
}
.cardItem {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;
  border-bottom: 1px solid #d1d5db;
  padding: 0.5rem 0;
}

.cardItem:last-child {
  border-bottom: 0;
}

.cardItem img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 20px;
  max-height: 150px;
}

/* .cardItem h {
  margin-top: 0.5rem;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600px;
} */

.image {
  width: 25%;
  height: 100%;
  padding-top: 6%;
}

.content {
  width: 75%;
  display: flex;
  padding-left: 2%;
  flex-direction: column;
  align-items: flex-start;
}

.cardItem p {
  /* margin: 0; */
  /* margin-top: 0.5rem; */
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 21px;
}

.cardItem .btnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600px;
  text-decoration: none;
}
