.popover-container {
  position: relative;
  display: inline-block;
}

.popover-trigger {
  // background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 1s;
}

.popover-content {
  position: absolute;
  top: 50%;
  right: 0%;
  // left: 50%;
  // transform: translateX(-50%);
  // margin-top: 10px;
  // background-color: white;
  // border: 1px solid #ccc;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  // border-radius: 4px;
  padding: 6px;
  z-index: 1000;
  white-space: nowrap;
  transition: all 1s;
  // transition: visibility .3s linear, opacity .3s ease, margin-top .3s ease, height .3s ease;
}
