.dream-mentor-main {
  background: #f4f7fb;
  position: relative;
  .swiper-pagination {
    .swiper-pagination-bullet-active {
      background-color: #f467b2 !important;
    }
    .swiper-pagination-bullet {
      background: #ccc;
    }
  }

  .swiper-wrapper {
    padding-bottom: 110px;
  }
}
.mentor-card {
  position: relative;
  width: 282px;
  height: 420px !important;
  height: auto;
  flex-shrink: 0;
  img {
    width: 100%;
  }
  .mentor-details {
    width: 246px;
    margin: 0 auto;
    padding: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 54%);
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    h3 {
      color: #000;
      text-align: center;
      //   font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    p {
      color: #000;
      //   font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 166.667% */
    }
    button {
      color: #113cc0;
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      svg {
        display: inline-block;
        margin-left: 6px;
      }
    }
  }
}

.featuredCareers {
  .carousel {
    display: grid;
    place-items: center;
  }

  .slider {
    margin: auto;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
  }

  .slidetrack {
    display: flex;
    width: calc(250px * 18);
    animation: scroll 40s linear infinite;
    animation-iteration-count: infinite;
    animation-delay: -2s;
  }

  .slidetrack:hover {
    animation-play-state: paused;
  }

  .slide {
    height: 319px;
    width: 300px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
    gap: 2px;
    min-width: unset !important;
  }

  .slide img {
    width: 100%;
    transition: transform 1s;
  }

  .slide img:hover {
    transform: scale(1.2) translateZ(20px);
  }
}
