.did-you-know-main {
  width: 100%;
  background: #00c798;
  .swiper-pagination {
    .swiper-pagination-bullet-active {
      background-color: #fff;
    }
    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.5);
    }
  }
}
