@keyframes horizontal-slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.slide-items-container:hover {
  animation-play-state: paused !important;
}
