.magazine-box {
  width: 133px;
  height: 175px;
  background: #173cba;
  flex-shrink: 0;
  border: 5px solid #fff;
  position: absolute;
  text-align: center;
  span {
    line-height: 14px;
    display: inline-block;
  }
  img {
    width: 103px;
    height: 80px;
  }
}
.subscribe-btn {
  border-radius: 37.455px;
  border: 2px solid #0c9;
  background: #fff;
  width: 80.081px;
  height: 19.878px;
  flex-shrink: 0;
  color: #383838;
  text-align: center;
  font-family: "Open Sans";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
}
.btn-styles {
  border-radius: 37.455px;
  border: 2px solid #fff;
  background: #173cba;
  width: 197.327px;
  height: 52px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media (min-width: 321px) and (max-width: 450px) {
  .magazine-box {
    width: 100px;
  }
  .subscribe-now-section {
    padding: 16px 0;
  }
  .subscribe-title {
    color: #fff;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 75px;
  }
  .subscribe-now {
    width: 115.924px;
    height: 33.737px;
    border-radius: 22.004px;
    border: 1.175px solid #fff;
    background: #173cba;
    color: #fff;
    text-align: center;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .magazine-box {
    width: 70px;
    height: 98px;
    padding: 0;
    left: 0;
    top: -32px;
    span {
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 5.115px;
      font-style: normal;
      font-weight: 700;
      line-height: 6.138px;
    }
    img {
      width: 53.02px;
      height: 41.083px;
    }
    button {
      width: 40.964px;
      height: 10.168px;
      border-radius: 19.16px;
      border: 1.023px solid #0c9;
      background: #fff;
      color: #383838;
      text-align: center;
      font-family: "Open Sans";
      font-size: 3.426px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .subscribe-now {
    width: 116px;
    height: 34px;
    font-size: 12px;
  }
}
