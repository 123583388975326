.button:hover {
  background-color: #0a2a7b;
  color: #f3f3f3;
}

.tooltip {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  background-color: #fff;
  color: #4c495c;
}
.tooltipTarget:hover .tooltip {
  display: block;
}

/* Style for the entire scrollbar */
.filter-section ::-webkit-scrollbar {
  width: 6px;
  // height: 12px; /* height of the scrollbar */
}

/* Style for the scrollbar track */
.filter-section ::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 7px;
}

/* Style for the scrollbar thumb */
.filter-section ::-webkit-scrollbar-thumb {
  background-color: #00b489;
  border-radius: 7px;
}
