.examName{
    color: #383838;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}
.names{
    color: #7C7C7C;

font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */

}