@media screen and (min-width: 1280px) {
  .topAdministator {
    .firstslide {
      padding-top: 239px;
    }

    .secondslide {
      padding-top: 192px;
    }

    .thirdslide {
      padding-top: 145px;
    }

    .fourthslide {
      padding-top: 73px;
    }
  }
}

.topAdministator {
  padding-bottom: 90px;
}
.progressBar {
  height: 3px;
  width: 100%;
  background-color: #d9d9d9;
  .progress-bar-inner {
    height: 100%;
    background-color: #ff66cc;
    transition: width 0.3s ease-in-out;
  }
}
@media (max-width: 360px) {
  .paginationSec {
    width: 100%;
  }
}

.slidetrack {
  display: flex;
  width: calc(280px * 18);
  animation: scroll 20s linear infinite !important;
  animation-iteration-count: infinite;
  animation-delay: -2s;
}

.slidetrack:hover {
  animation-play-state: paused !important;
}
