.collegename-cs {
  color: #000;
  height: 29px;
  font-family: "Open Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.17px;
}

.degree {
  color: var(--Neutrals-2, #25242b);
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rating {
  color: var(--Neutrals-2, #25242b);
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.compares {
  font-family: "Open Sans";
  font-size: 14.78px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5.05px;
}
