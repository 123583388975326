.sticky-container {
  position: relative;
}

.section {
  height: 100vh; /* Ensure each section has enough height */
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease, z-index 0.5s ease;
}

.sticky-section-active {
  opacity: 1;
  position: relative; /* Let sticky handle the positioning */
}

.c-left {
  width: 558px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  h3 {
    color: #0e2059;
    // font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 66px; /* 132% */
    span {
      color: #05b38b;
    }
  }
  .search-box {
    position: relative;
    .search-input {
      display: flex;
      width: 385px;
      padding: 14px 20px 14px 50px !important;
      align-items: center;
      gap: 6px;
      border-radius: 50px;
      border: 1px solid #e0e0e0;
      padding: 20px;
    }
    .search-icon {
      position: absolute;
      left: 25px;
      top: 16px;
    }
  }
}

.c-right {
  width: 679px;
  height: 469px;
  flex-shrink: 0;
  border-radius: 20px;
  overflow: hidden;
}

.c-featured-courses-section {
  h3 {
    color: #231f40;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 40px;
  }
}

.explore-dream-courses-section {
  h3 {
    color: #231f40;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 30px;
  }
}

.studentTestimonials {
  .swiper-slide {
    height: 193px !important;
    &.swiper-slide-next {
      left: 20%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      margin-bottom: 0 !important;
    }
  }
}


