.predictor-subheading
{
    color: #383838;

font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 27px; 
}
.predictor-heading{
    color: #173CBA;
font-family: Roboto;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 55px; 
}
.predictor-para{
    color: #383838;

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    
    

}

@media (min-width: 760px) and (max-width: 1020px) {
    .predictor-heading {
      font-size: 20px; /* Adjusted font size for medium screens */
      line-height: 22px; 

    }
    .predictor-subheading{
        font-size: 14px; /* Adjusted font size for medium screens */
        line-height: 16px; 
    }
    .predictor-para{
        font-size: 12px; /* Adjusted font size for medium screens */
        line-height: 14px; 

    }
}

.arrow-svg {
    transition: transform 0.3s ease; /* Smooth transition */
  }
  
  button:hover .arrow-svg {
    transform: translateX(5px); /* Adjust the amount of movement as needed */
  }
  