.contact-nav {
  z-index: 100000;
}

.icon {
  cursor: pointer;
  display: inline-block;
  padding: 3px 0 0;
}

.contact-text {
  font-size: 13px;
  font-weight: 500;
}

.contact-text {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.navbar {
  display: flex;
  justify-content: center;
}

.nav-list-contact {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item-contact {
  margin-right: 10px;
}

.nav-link-contact {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.megamenu {
  position: absolute;
}

.submegamenu {
  
}
.submegamenu::-webkit-scrollbar {
  width: 4px;
}

.submegamenu::-webkit-scrollbar-thumb {
  background-color: var(--thumb-bg, #4caf50);
  border-radius: 4px;
}

.submegamenu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.superheader {
  color: #173cba;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

.subheader {
  color: #0C9;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.listtabs {
  display: flex;
  height: auto; 
  padding-right: 20px;
  align-items: center;
  white-space: normal; 
  word-break: break-word; 
  overflow-wrap: break-word;
  font-size: 12px;
}

