.CareerBanner {
  .career {
    width: 6.25rem;
    position: relative;
    height: 28.125rem;
    background-color: #dbdbdb;
    background-position: center bottom;
    background-size: cover;
    padding: 0.625rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    .title {
      position: absolute;
      left: 3.4rem;
      bottom: 1.7rem;
      margin: 0;
      line-height: 1.5rem;
      transform-origin: left bottom;
      transform: rotate(-90deg) translateY(0);
      width: 10rem;
      transition: all 0.3s ease-in-out;
      font-size: 24px;
      font-weight: 700;
      color: #fff;
      line-height: 28.13px;
      white-space: nowrap;
    }
    &.active {
      .title {
        transform: rotate(-90deg) translateY(-4rem);
        opacity: 0;
      }
    }
    &.active:hover {
      .title {
        transform: rotate(-90deg) translateY(-4rem);
        opacity: 0;
      }
    }
    .career-content {
      opacity: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 15px 20px 10px 10px;
      transform: translateX(50%);
      transition: all 0.4s ease-in-out;
    }
    &.active .career-content {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
.arrow-btn {
  background: #0c9;
  // border: none;
  cursor: pointer;
  // padding: 10px;
  position: relative;
  // overflow: hidden;
}

.arrow-icon {
  display: inline-block;
  transition: transform 0.3s ease-out;
}

.arrow-btn:hover .arrow-icon {
  transform: translateX(6px);
}
