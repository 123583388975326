.college-predictor-details {
  .description {
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
      font-size: 1rem;
    }

    ul {
      margin-bottom: 1rem;
      padding-left: 1rem;
      list-style-type: none;
      position: relative;
      margin-left: 1rem;
      li {
        font-size: 1rem;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          background-image: url("../../../../images/college/tikmark.svg");
          background-repeat: no-repeat;
          display: flex;
          left: 0;
        }
      }
    }

    h3,
    h2 {
      font-size: 1rem;
    }
    table {
      background: #ffffff;
      width: 100%;
      tr {
        th,
        td {
          border: 0.3px solid #7c7c7c;
          text-align: center;
          padding: 10px;
          color: #7c7c7c;
        }
      }
    }
  }
}
