.image-container {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}
.image-container:hover {
    transform: scale(1.2); 
}
.blog-exam-image{
    transition: transform 0.3s ease-in-out;
}
.image-container:hover img  {
    transform: scale(1.2); 
}


.blog-image-title-container:hover {
 
    .image-container img{
        transform: scale(1.2);
    }
}



.premium-blog-link{
    opacity: 0;
    overflow: hidden;
    transition: all .2s ease-out;
    transform: translateY(10%);
    
}

.premium-image-container:hover .premium-blog-link {
   
    opacity: 1;
    transform: translateY(-0%);
}