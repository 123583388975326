.blog-content {
  text-align: justify;
  overflow-wrap: break-word;
  word-break: keep-all;
  word-spacing: -0.5px;
  white-space: normal;

  /* Table Styling */
  table {
    width: 100% !important;
    overflow-x: auto;
    border-collapse: collapse;
    /* To avoid double borders */
    margin: 1rem 0;
    font-size: 0.9rem;
    color: #333;
  }

  thead {
    background-color: #eef2ff;
    /* Header background */
  }

  th,
  td {
    border: 1px solid black !important;
    padding: 0.5rem;
    text-align: left;
  }

  th {
    font-weight: bold;
    color: #4c495c;
  }

  tbody tr:nth-child(even) {
    background-color: #eef2ff;
  }

  tbody tr:nth-child(odd) {
    background-color: #fff;
  }

  @media (max-width: 768px) {
    table {
      width: 100% !important;
      overflow-x: auto;
    }

    th,
    td {
      padding: 0.3rem;
      font-size: 0.8rem;
    }
  }
}

.blog-content p {
  margin: 0.5em 0;
  line-height: 1.5;
  color: #333;
  /* Adjust text color */
}

@media (max-width: 600px) {
  .blog-content p span strong {
    font-size: 4vw !important;
    white-space: initial;
    overflow-wrap: break-word;
    word-break: keep-all;
    word-spacing: 2.5px;
  }

  .blog-content p span {
    font-size: 4vw !important;
    overflow-wrap: break-word;
    word-break: keep-all;
  }
}

.blog-content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.blog-content ol {
  margin-left: 20px;
  list-style-type: number;
}

.blog-content img {
  max-width: 100%;
  /* Makes images responsive */
  height: auto;
}

.blog-content table {
  width: 100% !important;
  overflow-x: scroll;
}

.blog-content table,
.blog-content th,
.blog-content td {
  border: 1px solid black !important;
  padding: 0.5rem;
}

@media (max-width: 768px) {

  .blog-content h1,
  .blog-content h2,
  .blog-content h3,
  .blog-content h4,
  .blog-content h5,
  .blog-content h6,
  p.heading10,
  p.heading20,
  p.heading30 {
    text-align: left !important;
  }

  .blog-content table {
    width: 100% !important;
    overflow-x: auto;
  }
}

/* Additional Rule to Ensure Boldness */
.blog-content h3 span {
  font-family: "Georgia", "Palatino", serif;
  /* Ensure font supports bold */
  font-weight: bold !important;
  font-size: 14pt;
  /* Optional to maintain font size */
}