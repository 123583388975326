// .search-box {
//   position: relative;
//   .expanded {
//     position: absolute;
//     right: 0;
//   }
// }
.searchbox {
  color: #717171;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #d9d9e5;
  border-radius: 5px;
  padding: 0.375rem 2.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
}
