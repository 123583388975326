.custom-checkbox {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.custom-checkbox:checked {
  background-color: #ffffff;
}

.custom-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 5px;
  width: 7px;
  height: 12px;
  border: solid #00b489;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
