// flex items-center justify-center w-10 h-10 p-4 border text-white text-sm rounded-full bg-blue border-blue

.page-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  color: #ffffff;
  background: #113cbf;
  border-radius: 50%;
  cursor: pointer;
}

.page-number.active {
  background: #00c798;
  color: black;
}
