.dreamcareerJourney-swiper {
  padding-bottom: 20px;
  .swiper-button-next,
  .swiper-button-prev {
    color: black;
    font-size: 24px;
    top: 50%;
    transform: translateY(-50%);
  }

  .swiper-button-prev {
    left: -20px;
  }

  .swiper-button-next {
    right: -20px;
  }

  .swiper-slide {
    opacity: 0.5;
    transform: scale(0.9);
  }

  .swiper-slide-active {
    opacity: 1;
    transform: scale(1);
  }
}
.learn-tab .tabscontent-highlight,
.experience-tab .tabscontent-highlight {
  background-color: #fff;
  border: #00cc99 1px solid;
}
