@media (min-width: 321px) and (max-width: 450px) {
  .top-mentors h3 {
    color: var(--Black, #000);
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 128.571% */
    text-transform: uppercase;
  }
  .top-mentors p {
    color: #292929;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
  }
}
.map-mentor {
  border: 1px solid #173cba;
  color: white;
  background-color: #173cba;
}
