.banner-right {
  width: 40%;
  justify-content: center;
}
.small-circle {
  position: relative;
}

// modal styles starts here
.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 660px;
  height: 360px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#close-btn {
  background-color: #ccc;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
