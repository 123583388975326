.blog-content {
  a {
    color: #1e90ff;
    text-decoration: underline;
    transition: color 0.2s ease-in-out, text-decoration 0.2s ease-in-out;
    width: 100%;
    word-wrap: break-word;

    &:hover {
      color: #1c3d7d;
      text-decoration: none;
    }
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
  }
}
