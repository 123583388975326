.success-story {
  .success-story-card {
    scale: 0.8;
    transition: all 2s ease-in-out;
  }

  .swiper-slide-next {
    .success-story-card {
      scale: 1.1;
      border: 1px solid #173cba;
    }
  }
}
