.dreamCareerSec {
  display: inline-block;
  vertical-align: top;
  -webkit-transition: height 500ms ease;
  -o-transition: height 500ms ease;
  transition: height 500ms ease;
  .dreamCareerIcon {
    position: relative;
    width: auto;
    height: 151px;
    cursor: pointer;
    &:after {
      display: block;
      position: absolute;
      bottom: -21px;
      left: 50%;
      margin-left: -10px;
      width: 0;
      height: 0;
      content: "";
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom-style: solid;
      border-bottom-width: 0px;
      -webkit-transition: border-bottom-width 500ms ease;
      -o-transition: border-bottom-width 500ms ease;
      transition: border-bottom-width 500ms ease;
      border-bottom-color: #cacaca;
    }
  }
  .dreamCareerSecChild {
    margin-top: 20px;
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    max-height: 0;
    -webkit-transition: max-height 500ms ease;
    -o-transition: max-height 500ms ease;
    transition: max-height 500ms ease;
    border-radius: 8px;
    .careerExpandHeader {
      margin: 0 auto;
      width: 100%;
    }
  }
  &.expanded .dreamCareerIcon:after {
    border-bottom-width: 10px;
  }
}
