.mentor-network .swiper-button-prev,
.mentor-network .swiper-button-next {
  width: 40px;
  height: 40px;
  display: block !important;
  background-color: #173cba;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px;
}

.mentor-network .swiper-button-prev::after,
.mentor-network .swiper-button-next::after {
  font-size: 16px;
  color: #ffffff;
}

@media (min-width: 768px) {
  .mentor-network .swiper-button-prev {
    left: -20px;
  }
}

@media (min-width: 768px) {
  .mentor-network .swiper-button-next {
    right: 118px;
  }
}

.scrollbar-hide {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
