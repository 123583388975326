.tabs {
    display: flex;
  }
  .tab-name{
    color: #383838;

font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .custom-tab {
    padding: 4px;
    cursor: pointer;
    // border-bottom: 2px solid transparent;
    border: 2px solid grey;
    border-radius: 5px;
    // transition: all 0.3s;
    margin-right: 5px
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-auto-rows: minmax(30px, auto); /* Adjust row height based on content */
    gap: 16px; /* Adjust spacing between grid items */
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .grid-item {
    display: flex;
    border: 1px solid #ddd; /* Optional: for visual separation */
    padding: 8px;

    background-color: white;
  }
  .custom-tab.active {
    
    font-weight: bold;
    // color: #494677;
    background-color: #00CC99;
  }

  .h3{
    color: #FFF;

font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }

  @media (max-width: 640px) { /* Adjust breakpoint as needed */
    .grid-container {
      grid-template-columns: 1fr; /* 1 column */
      grid-auto-rows: minmax(30px, auto); /* Adjust row height for smaller screens */
      grid-template-rows: repeat(4); /* 4 rows */
    }
    .h3{
      color: #FFF;

      font-family: "Open Sans";
      font-size: 14.857px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  @media (min-width: 640px) and (max-width:1024px) {
    .grid-container{
    grid-template-columns: repeat(2, 1fr); /* 3 columns */
    }
  }