@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.progress-bar.completed {
  width: 100%;
}

.progress-bar.current {
  animation: progress 5s linear forwards;
}

.progress-bar.current.paused {
  animation-play-state: paused;
}

.progress-bar {
  width: 0%;
  height: 0.15rem;
  background-color: #173cba;
}
