.StudentInfoProps {
  .swiper-pagination-bullet {
    background-color: #fff;
    &.swiper-pagination-bullet-active {
      background-color: #00cc99;
    }
  }
  .swiper-pagination {
    position: relative;
  }
}
.parent-container::after {
  content: "";
  display: table;
  clear: both;
}

.universityLogoDeamser {
  position: relative;
  width: 180px;
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
}
@media screen and (max-width: 787px) {
  .universityLogoDeamser {
    width: 130px;
  }
}
