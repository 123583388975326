.Cards-stream {
    position: relative; /* Ensure the pseudo-element is positioned relative to the card */
    overflow: hidden; 
    z-index: 1; 
  }
  
  // .Cards-stream::before {
  //   position: absolute;
  //   content: '';
  //   width: 15.6rem;
  //   height: 9.3rem;
  //   border-radius: 207% 106%;
  //   background: linear-gradient(to right, #eed9ce 0, #ff7c002e 100%);
  //   left: 75%;
  //   top: 0;
  //   transform: translateX(-50%);
  //   z-index: 1; /* Place behind the content */
  // }
  // @media (max-width: 768px) {
  //   .Cards-stream::before {
  //     width: 10.6rem;
  //   height: 6.3rem;
  //     border-radius: 100% 75%;
  //   }
  // }
  
  // @media (max-width: 480px) {
  //   .Cards-stream::before {
  //     width: 9rem;
  //   height: 8.3rem;
  //     border-radius: 75% 50%;
  //   }
  // }
  
  // .Cards-stream::after {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   width: 30%;
  //   height: 30%;
  //   border: 2px solid;
  //   transition: all 0.6s ease;
  //   border-radius: 2px;
  //   z-index: 0; /* Place behind the content and :before design*/
  //   bottom: 0;
  //   right: 0;
  //   border-top-color: transparent;
  //   border-left-color: transparent;
  //   border-bottom-color: #ffca10;
  //   border-right-color: #ffca10;
  //   background:white;
  //   opacity: 1;
  // }
  
  // .Cards-stream .top-left-box {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   width: 30%;
  //   height: 30%;
  //   border: 2px solid;
  //   transition: all 0.6s ease;
  //   border-radius: 2px;
  //   z-index: 0; /* Place behind the content */
  //   top: 0;
  //   left: 0;
  //   border-top-color: transparent;
  //   border-left-color: transparent;
  //   border-bottom-color: transparent;
  //   border-right-color: transparent;
  //   background: #e4f8f8;
  //   opacity: 1;
  // }
  
  // .Cards-stream:hover::after {
  //   width: 100%;
  //   height: 100%;
  //   background: white; /* Hide the gradient */
  //   opacity: 1;
  //   z-index: 3;
  // }
  // .Cards-stream:hover .top-left-box
  // {
  //   width: 100%;
  //   height: 100%;
  //   background: #e4f8f8; /* Hide the gradient */
  //   opacity: 1;
  //   z-index: 2;
  // }
  .Cards-stream .content {
    position: relative;
    z-index: 4; /* Ensure the content is on top of the pseudo-element */
  }
  .heart
  {
    z-index: 50;
  }
  .cta
  {   

    z-index: 5;
  }
  .arrow-icon {
    transition: transform 0.2s ease-in-out;
  }
  
  .arrow-icon:hover {
    transform: translateX(6px); // Adjust the value as needed
  }