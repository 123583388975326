@keyframes overlay-reveal {
  from {
    transform: translateX();
  }

  to {
    transform: translateX(100%);
  }
}

.overlay-reveal {
  animation: overlay-reveal 1s ease-in-out forwards;
}

.socialdown {
  /* transform: translate(-50%, -50%); */
  position: relative;
  display: block;
  display: flex;
  overflow: hidden;
  /* Ensure the overflow is hidden to contain the transition effect */
  transition: background-color 1s ease, box-shadow 1s ease;
  /* Slow down the transition */
  /* Smooth transition for background color and box-shadow */

  img {
    transition: filter 1s ease;
    /* Slow down the transition */
    /* Smooth transition for filter property */
    fill: #fff;
    /* Set initial color to white */
  }

  &:hover {
    background: #fff;
    /* Change background color on hover */
    box-shadow: 0 0 5px #173cba;

    img {
      filter: invert(100%);
      /* Convert white to blue on hover using filter */
    }
  }
}
.DotsAndShadesHome {
  position: absolute;
  top: 150px;
  left: -120px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -9;
  transform: scale(2.3);
  transition: (-webkit-transform 1s ease-in-out);
}
