@keyframes translateY {
    0% {
        transform: translateY(0);
    }

   
    100% {
      transform: translateY(-200px);
    }
  }
  @keyframes translateY-2 {
    0% {
        transform: translateY(0);
    }
 
   
    100% {
      transform: translateY(-200px);
    }
  }
  
  .animate-translate {
    animation: translateY 0.50s ease-in-out forwards;
  }
  .animate-translate-2 {
    animation: translateY-2 0.50s ease-in-out forwards;
  }
  
  .animate-translate-delay-1 {
    animation-delay: 0.1s;
  }
  .banner-search-box {
    position: relative;
  }
  
  .search-suggestion {
    z-index: 100;
    position: absolute;
    top: 368px;
  }
  .searchz
  {
    z-index: 1000000;
  }

  .arw-button-college {
    background: none;
   
    cursor: pointer;
  }
  .arrow-icon {
    display: inline-block;
    transition: transform 0.3s ease-out;
  }
  .arw-button-college:hover .arrow-icon {
    transform: translateX(6px); /* Adjust distance of movement */
  }
  .compare-heading{
    color: #173CBA;
font-family: Roboto;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 150% */
text-transform: uppercase;
  }

  .compare-subheading{
    color: var(--Neutrals-2, #25242B);
/* H14 - Open Sans */
font-family: "Open Sans";
font-size: 16px;
font-style: normal;

font-weight: 400;
line-height: 32px;
  }