.result-heading{
    color: #383838;

font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.sub-heading{
    color: #383838;

font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: normal;
}

.result-para{

    color: #383838;

font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 300;
line-height: normal;
}

/* ServicesList.css */
.services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
  }
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: 1fr; 
    }
  }
  .service-item {
    color: #0C9;

text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 21px;
padding-right: 20px;
padding-top: 5px;
  }
  .download-brochure{
    color: #FFF;

text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 21px;
  }