.bannerStyles {
  strong {
    font-weight: 700;
  }

  .blueColor {
    background-color: var(--blue);
    color: white;
  }

  .lightGreenColor {
    background-color: var(--light-green);
    color: white;
  }

  .lightPinkColor {
    background-color: var(--light-pink);
    color: white;
  }
}

@media (min-width: 1440px) {
  .examBannerRows,
  .lastExamBannerRows {
    display: flex;
    gap: 26px;
    .examBannerLogos {
      background-color: white;
      padding: 6px;
      width: 145px;
      height: 93px;
      overflow: hidden;
      border-radius: 30px;
      .examsBannerImg {
        width: 100%;
        height: 50px;
        object-fit: contain;
      }
    }
  }
  .bannerSearchBox,
  .bannerSectionLogos {
    width: 50%;
  }
  .lastExamBannerRows {
    margin-top: 10px;
  }
}
@media (min-width: 1280px) and (max-width: 1440px) {
  .examBannerRows,
  .lastExamBannerRows {
    display: flex;
    gap: 26px;
    .examBannerLogos {
      background-color: white;
      padding: 6px;
      width: 145px;
      height: 93px;
      overflow: hidden;
      border-radius: 30px;
      .examsBannerImg {
        width: 100%;
        height: 50px;
        object-fit: contain;
      }
    }
  }
  .bannerSearchBox {
    width: 40%;
  }
  .bannerSectionLogos {
    width: 60%;
  }
}
.BannerLines {
  position: absolute;
  z-index: 9;
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .BannerLines {
    width: 69%;
    top: 12%;
  }
  .examBannerRows,
  .lastExamBannerRows {
    display: flex;
    gap: 16px;
    .examBannerLogos {
      background-color: white;
      padding: 6px;
      width: 120px;
      height: 77px;
      overflow: hidden;
      border-radius: 30px;
      .examsBannerImg {
        width: 100%;
        height: 30px;
        object-fit: contain;
      }
    }
  }
  .bannerSearchBox {
    width: 40%;
  }
  .bannerSectionLogos {
    width: 60%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .BannerLines {
    width: 80%;
    top: 15%;
    left: 50.5%;
    transform: translateX(-50.5%);
  }
  .examBannerRows,
  .lastExamBannerRows {
    display: flex;
    gap: 16px;
    .examBannerLogos {
      background-color: white;
      padding: 6px;
      width: 100px;
      height: 64px;
      overflow: hidden;
      border-radius: 20px;
      .examsBannerImg {
        width: 100%;
        height: 20px;
        object-fit: contain;
      }
    }
  }
  .bannerSearchBox {
    width: 40%;
  }
  .bannerSectionLogos {
    width: 60%;
  }
}
@media (min-width: 640px) and (max-width: 768px) {
  .BannerLines {
    width: 59%;
    top: 14%;
    left: 50%;
    transform: translateX(-50%);
  }
  .examBannerRows,
  .lastExamBannerRows {
    display: flex;
    gap: 16px;
    .examBannerLogos {
      background-color: white;
      padding: 6px;
      width: 100px;
      height: 64px;
      overflow: hidden;
      border-radius: 20px;
      .examsBannerImg {
        width: 100%;
        height: 20px;
        object-fit: contain;
      }
    }
  }
  .bannerSearchBox {
    width: 100%;
  }
  .bannerSectionLogos {
    width: 100%;
  }
}
@media (min-width: 480px) and (max-width: 640px) {
  .BannerLines {
    width: 360px;
    top: 14%;
    left: 50%;
    transform: translateX(-50%);
  }
  .examBannerRows,
  .lastExamBannerRows {
    display: flex;
    gap: 16px;
    .examBannerLogos {
      background-color: white;
      padding: 6px;
      width: 100px;
      height: 64px;
      overflow: hidden;
      border-radius: 20px;
      .examsBannerImg {
        width: 100%;
        height: 20px;
        object-fit: contain;
      }
    }
  }
  .bannerSearchBox {
    width: 100%;
  }
  .bannerSectionLogos {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .bannerSection {
    gap: 16px;
  }
  .BannerLines {
    width: 280px;
    top: 20%;
    left: unset;
    transform: unset;
    margin: 0 auto;
  }
  .examBannerLogos p {
    font-size: 9px;
  }
  .examBannerRows,
  .lastExamBannerRows {
    display: flex;
    gap: 14px;
    .examBannerLogos {
      background-color: white;
      padding: 6px;
      width: 80px;
      height: 51px;
      overflow: hidden;
      border-radius: 20px;
      .examsBannerImg {
        width: 100%;
        height: 20px;
        object-fit: contain;
      }
    }
  }
  .bannerSearchBox {
    width: 100%;
  }
  .bannerSectionLogos {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .BannerLines {
    width: 265px;
    top: 21%;
    left: unset;
    transform: unset;
    margin: 0 auto;
  }
  .examBannerRows,
  .lastExamBannerRows {
    display: flex;
    gap: 13px;
    .examBannerLogos {
      background-color: white;
      padding: 6px;
      width: 75px;
      height: 46px;
      overflow: hidden;
      border-radius: 20px;
      .examsBannerImg {
        width: 100%;
        height: 20px;
        object-fit: contain;
      }
    }
  }
}
@media (max-width: 380px) {
  .BannerLines {
    display: none;
  }
  .heightSec {
  }
}
