.filter-scroll::-webkit-scrollbar {
  width: 6px;
}

.filter-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.filter-scroll::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 999px;
}

@supports (-moz-appearance: none) {
  .filter-scroll {
    scrollbar-width: thin;
    scrollbar-color: #fff transparent;
  }
}
