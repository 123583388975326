.college-predictor-intermediate {
  .description {
    p {
      font-size: 1rem;
      margin-top: 1.5rem;
    }

    h2 {
      font-size: 1rem;
      margin-top: 1.5rem;
    }
  }
}
