.lifetext:hover {
  background-color: #173cba;
  color: white;
}

.lifetext {
  border: none;
}

.career-para {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  /* Regular */
  line-height: 23px;
  color: #171a1f;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.career-number {
  font-family: "Epilogue", sans-serif;
  font-size: 55px;
  font-weight: 600;
  /* Regular */
  line-height: 70px;
  color: #173cba;
}

.career-number-sm {
  font-family: "Epilogue", sans-serif;
  font-size: 48px;
  font-weight: 650;
  /* Regular */
  line-height: 25px;
  color: #173cba;
}

/* Slick Slider arrow styles */
.career-testimonial-slider .slick-arrow {
  position: absolute;
  top: auto;
  bottom: 3rem;
  padding: 4%;
  /* Adjust the bottom position as needed */
  width: fit-content;
  transform: translateY(50%);
  /* Center the arrow vertically */
  cursor: pointer;
  z-index: 100;
  /* Ensure the arrows are above the slider content */
  font-size: 1.5rem;
  /* Adjust the arrow size as needed */
  color: blue;
  /* Set the arrow color */
}

.career-testimonial-slider .slick-disabled {
  display: none;
}

.career-testimonial-slider .slick-prev {
  left: 50%;
  transform: translate(-150%, 50%);
}

.career-testimonial-slider .slick-next {
  right: 50%;
  transform: translate(150%, 50%);
}

/* Slick Slider dots styles */
.career-testimonial-slider .slick-dots {
  display: flex !important;
  justify-content: center;
  bottom: 1.2rem;

  /* Adjust the position as needed */
}

.career-testimonial-slider .slick-dots li button:before {
  font-size: 12px;
  color: #173cba;
  opacity: 0.5;
}

.career-testimonial-slider .slick-dots li.slick-active button:before {
  color: #173cba;
  opacity: 1;
}
/* Font styles based on previous Tailwind config */
.text-inter-regular-14 {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
  line-height: 1.375rem; /* 22px */
  color: #171a1f;
}

.text-inter-regular-16 {
  font-family: "Inter", sans-serif;
  font-size: 1rem; /* 16px */
  font-weight: 400;
  line-height: 1.5rem;
}

.text-inter-semibold-20 {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem; /* 20px */
  font-weight: 600;
  line-height: 1.5625rem; /* 25px */
  color: #171a1f;
}

.text-inter-regular-14-sm {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
  line-height: 1.5rem; /* 22px */
  color: #171a1f;
}

.CTABtn {
  font-family: "Inter", sans-serif;
  padding: 0.5rem 2rem; /* Adjust as per your design */
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  background-color: #173cba; /* Primary color */
  border-radius: 0.5rem;
  transition: all 0.3s;
}

.CTABtn:hover {
  background-color: #ffc700; /* Secondary color */
  color: #171a1f; /* Black */
}
/* To match your component styling with the older tailwind setup */

/* Hero Section Headings */
.heading1 {
  font-family: "Epilogue", sans-serif;
  font-size: 3.4375rem; /* Matches h1-lg: 55px */
  line-height: 4.375rem; /* 70px */
  font-weight: 700;
  color: #171a1f;
}
@media (max-width: 768px) {
  .heading1 {
    font-size: 1.875rem; /* 46px */
    line-height: 2.75rem; /* 60px */
  }
}
.heading2 {
  font-family: "Epilogue", sans-serif;
  font-size: 2.875rem; /* Matches h2-lg: 46px */
  line-height: 3.75rem; /* 60px */
  font-weight: 600;
  color: #171a1f;
}
@media (max-width: 768px) {
  .heading2 {
    font-size: 1.875rem;
    line-height: 2.75rem;
  }
}

.heading5 {
  font-family: "Inter", sans-serif;
  font-size: 1rem; /* 16px */
  line-height: 1.625rem; /* 26px */
  font-weight: 600;
  color: #171a1f;
}

/* Text Styles */
.text1 {
  font-family: "Inter", sans-serif;
  font-size: 1rem; /* 16px */
  line-height: 1.875rem; /* 30px */
  font-weight: 400;
  color: #171a1f;
}

.text-inter-regular-14-sm {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem; /* 14px */
  line-height: 1.5rem; /* 22px */
  font-weight: 400;
  color: #171a1f;
}

.text-inter-bold-16 {
  font-family: "Inter", sans-serif;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  font-weight: 600;
}
.CTABtn {
  display: inline-block;
  font-family: "Inter", sans-serif;
  padding: 1rem 2rem; /* Adjust padding based on design needs */
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  background-color: #173cba; /* Primary color */
  border-radius: 10rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.CTABtn:hover {
  background-color: #ffc700; /* Secondary color */
  color: #171a1f; /* Black */
  border-radius: 0;
}
.CTABtn {
  border-radius: 10rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.CTABtn:hover {
  border-radius: 0;
}

.bg-primary {
  background-color: #173cba; /* Primary color from older config */
}

.bg-secondary {
  background-color: #ffc700; /* Secondary color from older config */
}

.bg-gray-300 {
  background-color: #e5e5e5;
}

.hover\:text-black:hover {
  color: #171a1f; /* Hover black text */
}

/* Tag Style in JobItem */
.bg-yellow-tag {
  background-color: #ffc700;
  color: #171a1f;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem; /* 16px */
  padding: 0.25rem 0.75rem;
  border-radius: 9999px; /* Rounded-full */
}

/* Font Families */
.font-epilogue {
  font-family: "Epilogue", sans-serif;
}
.font-inter {
  font-family: "Inter", sans-serif;
}

/* Font Sizes with Line Heights */
.text-h1-sm {
  font-size: 2.125rem;
  line-height: 2.8125rem;
}
.text-h1-lg {
  font-size: 3.4375rem;
  line-height: 4.375rem;
}
.text-h2-sm {
  font-size: 1.75rem;
  line-height: 2.375rem;
}
.text-h2-lg {
  font-size: 2.875rem;
  line-height: 3.75rem;
}
.text-h3-sm {
  font-size: 1.625rem;
  line-height: 2.125rem;
}
.text-h3-lg {
  font-size: 2rem;
  line-height: 2.8125rem;
}
.text-h4-sm {
  font-size: 1.25rem;
  line-height: 1.625rem;
}
.text-h4-lg {
  font-size: 1.5rem;
  line-height: 1.875rem;
}
.text-h5-sm {
  font-size: 1.125rem;
  line-height: 1.625rem;
}
.text-h5-lg {
  font-size: 1rem;
  line-height: 1.625rem;
}
.text-text1-sm {
  font-size: 1rem;
  line-height: 1.875rem;
}
.text-text1-lg {
  font-size: 1.125rem;
  line-height: 1.875rem;
}
.text-text2-sm {
  font-size: 0.875rem;
  line-height: 1.625rem;
}
.text-text2-lg {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-text3-sm {
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.text-text3-lg {
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.text-inter-bold-32 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.75rem;
}
.text-inter-regular-18 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.875rem;
}

/* Font Weights */
.font-regular {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}

/* Drop Shadow */
.drop-shadow-xl {
  box-shadow: 0 0 10px rgba(26, 60, 184, 0.5);
}

/* Colors */
.bg-primary {
  background-color: #173cba;
}
.bg-secondary {
  background-color: #ffc700;
}
.text-primary {
  color: #173cba;
}
.text-secondary {
  color: #ffc700;
}
.text-tertiary {
  color: #6f6f6f;
}
.text-white {
  color: #ffffff;
}

/* Grayscale Colors */
.bg-gray-100 {
  background-color: #f5f5f5;
}
.bg-gray-200 {
  background-color: #e5e5e5;
}
.bg-gray-300 {
  background-color: #d4d4d4;
}
.text-gray-500 {
  color: #737373;
}
.text-gray-700 {
  color: #404040;
}

/* Slate Colors */
.bg-slate-100 {
  background-color: #f5f7fa;
}
.bg-slate-500 {
  background-color: #627d98;
}
.text-slate-700 {
  color: #334e68;
}

/* Custom Utility Classes */

.heading3 {
  font-family: "Epilogue", sans-serif;
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 2.125rem;
}
.heading4 {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.625rem;
}
.heading5 {
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.625rem;
}
.text1 {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.875rem;
}
.text2 {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.625rem;
}
.text3 {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
}
.text-inter-24-semibold {
  font-family: "Inter", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
}
.text-inter-bold-16 {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.text-inter-bold-20 {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.875rem;
}
.text-epilogue-semibold-46 {
  font-family: "Epilogue", sans-serif;
  font-size: 2.875rem;
  font-weight: 600;
  line-height: 3.75rem;
}
.text-epilogue-semibold-28 {
  font-family: "Epilogue", sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.375rem;
}
