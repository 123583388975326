.Bannerearth {
  transform-origin: top left;
  transform: scale(0.5);
}

@media (min-width: 450px) and (max-width: 768px) {
  .Bannerearth {
    transform: scale(0.6);
  }
}
@media (min-width: 321px) and (max-width: 450px) {
  .Bannerearth {
    transform: scale(0.4);
  }
}
@media (max-width: 320px) {
  .Bannerearth {
    transform: scale(0.3);
    right: -90px;
  }
}
@media (min-width: 769px) and (max-width: 1280px) {
  .Bannerearth {
    transform: scale(0.7);
  }
}
@media (min-width: 1140px) and (max-width: 1280px) {
  .Bannerearth {
    right: 17%;
  }
}
@media (min-width: 960px) and (max-width: 1024px) {
  .Bannerearth {
    right: 10%;
    transform: scale(0.6);
  }
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInDelayed {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.initial-blink {
  animation: blink 0.8s ease-in-out 6 forwards;
}

.center-fade-in {
  animation: blink 0.8s ease-in-out 4 forwards;
  animation-delay: 1.6s;
}

.group-fade-in {
  animation: fadeInDelayed 0.5s forwards;
  animation-delay: 2.5s;
}
.vector-fade-in {
  animation: fadeInDelayed 0.5s forwards;
  animation-delay: 3.7s;
}
.vector1-fade-in {
  animation: fadeInDelayed 0.5s forwards;
  animation-delay: 3.7s;
}
.vector2-fade-in {
  animation: fadeInDelayed 0.5s forwards;
  animation-delay: 4.3s;
}
.vector3-fade-in {
  animation: fadeInDelayed 0.5s forwards;
  animation-delay: 4.7s;
}

.image1-fade-in {
  animation: fadeInDelayed 0.5s forwards;
  animation-delay: 4s;
}

.image2-fade-in {
  animation: fadeInDelayed 0.5s forwards;
  animation-delay: 4.5s;
}

.image3-fade-in {
  animation: fadeInDelayed 0.5s forwards;
  animation-delay: 5s;
}
.banner-search-box {
  position: relative;
}

.searchSuggestion {
  max-height: 300px;
  overflow-y: auto;
}

.scrollbar-custom::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar-custom {
  scrollbar-width: thin;
  scrollbar-color: #ccc #f1f1f1;
}

.image-text {
  color: #fff;
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px 12px;
  border-radius: 4px;
  margin-top: 16px;
  width: 100%;
  text-align: center;
}
.arw-button {
  background: none;
  padding-top: 7px;
  padding-bottom: 7px;

  cursor: pointer;
}
.arrow-icon {
  display: inline-block;
  transition: transform 0.3s ease-out;
}
.arw-button:hover .arrow-icon {
  transform: translateX(6px);
}
