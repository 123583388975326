.title-dreams{

    color: #173CBA;

text-align: center;
font-family: Roboto;
font-size: 40px;
font-style: normal;
font-weight: 300;
line-height: 80px;
}

.comment-dreams{
    color: #173CBA;
    text-align: center;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    
}

.user-dreams{
    position: relative;
}
.name-dreams{
    color: #173CBA;

    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px; 
}
.uni-dreams{
    color: #173CBA;
    font-family: Roboto;
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    line-height: 20px;
    text-align: center;

}


.name-cards{

    color: #383838;

    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.experience-cards{

    color: #747474;

    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 168.75% */
}

.role-cards{
    color: #747474;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;

}

.cards-container
{
overflow:visible;
}
.cards-individual {
    overflow: visible;
    position: relative; 
    background-color: transparent; /* Fully transparent background */
    /* Ensure relative positioning if absolute positioning is used inside */
}

.cardimage {
    position: relative;
    background-color: transparent; /* Fully transparent background */

  
    z-index: 100; /* Ensure it's above other content */
}


.curved-text-container {
    display: flex;
   
    height: 300px; /* Adjust height as needed */
  }
  .top-men{

    color: #0C9;
text-align: center;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 27px; /* 192.857% */
  }

  .arrow-ani {
    display: inline-block;
    transition: transform 0.3s ease-out;
  }
  .arrow-ani:hover {
    transform: translateX(6px); /* Adjust distance of movement */
    cursor: pointer;
  }
  @media (max-width: 480px) {
.title-dreams{
    color: #173CBA;

text-align: center;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: 50.919px; 
}
.curved-text-container text {
    font-size: 36px; /* Smaller font size for small screens */
  }
  }