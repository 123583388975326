.vs{
    color: #000;

    font-family: Rakkas;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.17px; /* 100.775% */

}
.title-cs{
    color: #000;

font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22.17px;
}
.compare{
    color: #FFF;

font-family: "Open Sans";
font-size: 14.78px;
font-style: normal;
font-weight: 600;
line-height: normal;
}