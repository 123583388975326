.chevron-icon {
  padding: 2px 0;
  img {
    width: 18px;
    height: 18px;
    margin-left: 4px;
    margin-right: 16px;
  }
}

.magnifier-icon {
  cursor: pointer;
  padding: 9px 0;
}

/* For desktop view */
.logo-section {
  width: 250px; /* Increase the width */
  height: 50px; /* Increase the height */
  margin-left: -10px; /* Move left */
  margin-top: -5px;   /* Move up */
  flex-shrink: 0;
}

/* For mobile view */
@media (max-width: 768px) {
  .logo-section {
    width: 220px; /* Adjust size for mobile */
    height: 40px; /* Adjust height for mobile */
    margin-left: -25px; /* Move slightly left on mobile */
    margin-top: -5px; /* Move slightly up on mobile */
  }
}
.navbar {
  display: flex;
}
.mobile-menu {
  z-index: 100;
}
.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .nav-list {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 2px;
  }
}

@media (min-width: 1024px) {
  .nav-list {
      display: flex;
      gap: 16px;
  }
}


.nav-link {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 768px) {
  .nav-link {
    font-size: 16px;
  }
}

.login-button {
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: #113cc0;
  color: #fff;
}
.submegamenumobile {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #4caf50 transparent;
}

.submegamenumobile::-webkit-scrollbar {
  width: 8px;
}

.submegamenumobile::-webkit-scrollbar-thumb {
  background-color: #4caf50;
  border-radius: 4px;
}

.submegamenumobile::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.listtabsmobile {
  display: flex;
  padding-right: 120px;
  align-items: center;
  align-self: stretch;
  font-size: 12px;
}

.superheadermobile {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.subheadermobile {
  color: #0C9;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.main-nav {
  position: relative;
  z-index: 1000000;
  background-color: #fff;
}
.mobilemenu {
  z-index: 1000000;
}
