.world-class-uni-container {
  text-align: center;
  padding: 20px;
}

.description-uni {
  color: #747474;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.card-container-uni {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  position: relative;
}

.card-uni {
  position: relative;
  overflow: auto;
  border-radius: 10px !important;
  transition: transform 0.3s;
}

.card-uni.even {
  margin-top: 5rem;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-video {
  width: 100%;
  z-index: 10;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  display: none;
  border: none; /* Remove iframe border */
}
.card-video-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; // Ensure the video does not overflow
  border-radius: 12px; // Match the card's border radius
}
.card-uni:hover .card-video {
  display: block;
}

.card-uni:hover .card-image {
  display: block;
}

.heading-uni {
  color: #173cba;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

@media (max-width: 480px) {
  .heading-uni {
    color: #173cba;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }

  .description-uni {
    color: #747474;
    text-align: left;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
  }
}
.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}
