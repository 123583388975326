
.name-featured{
color: var(--Black, #000);

text-align: center;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}