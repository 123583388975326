.tabs {
    display: flex;
  }
  
  .custom-tab-trending {
    padding: 4px;
    cursor: pointer;
    // border-bottom: 2px solid transparent;
    border: 1px solid #173CBA;
    border-radius: 10px;
    // transition: all 0.3s;
    margin-right: 5px
  }
  
  .custom-tab.active {
    
    font-weight: bold;
    // color: #494677;
  }