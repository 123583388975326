.buttonTabs {
  overflow-x: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.buttonTabs::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.buttonTab.active {
  background-color: #14e0ad !important;
}

button.active {
  background-color: #14e0ad;
  color: white;
}

.sub-tabs button {
  background-color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sub-tabs button.active {
  background-color: #14e0ad;
  color: white;
}

.content-display h3 {
  margin-bottom: 10px;
  font-size: 24px;
}

.content-display p {
  font-size: 16px;
}
.applicationFeeTooltip::before {
  content: "";
  position: absolute;
  top: 35%;
  left: 0%;
  margin-left: -10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #ffffff transparent transparent; /* Black arrow */
}
/* From Uiverse.io by adamgiebl */
.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dot {
  height: 14px;
  width: 14px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #14e0ad;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #173cba;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #14e0ad;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}
