.why-clg-mentor-main {
  background: linear-gradient(to right, #fff0e1, #f5eef5);
  // height: 809.64px;
  flex-shrink: 0;
}

.img1 {
  position: relative;
  .badge {
    position: absolute;
    top: -32px;
    left: 6%;
  }
}

.img3 {
  position: relative;
  .total-students {
    position: absolute;
    bottom: 25px;
    left: -25px;
    width: 170.69px;
    height: 76px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.08);
    padding: 15px;
    p {
      color: #6f6b80;
      // font-family: Urbanist;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
