.icon-hidden {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.2s ease-out;
}

.blog-page-card:hover .icon-hidden {
  opacity: 1;
  transform: translateX(0);
}

.related-blog:last-child {
  border: none !important;
}

.listing_card_dropdown_content {
  background-color: #f1f1f1;
  box-shadow: 0 8px 16px 0 #0003;
  display: none;
  min-width: 160px;
  position: absolute;
  right: 0;
  z-index: 1;
}

.listing_card_dropdown:hover .listing_card_dropdown_content {
  display: block;
}
.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
