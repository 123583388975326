.btn-style {
  display: flex;
  padding: 14px 34px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 37.455px;
  border: 2px solid #fff;
  background: #173cba;
}

@media (min-width: 321px) and (max-width: 450px) {
  .success-heading img {
    width: 20px;
    height: 20px;
  }
  .success-heading h3 {
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .success-btn button {
    width: 127px;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 12px;
  }
}
