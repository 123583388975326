@keyframes blogFooterPulse {
  from {
    opacity: 1;
    height: 0;
    width: 0;
  }
  to {
    opacity: 0;
    height: 6rem;
    width: 6rem;
  }
}

.blog-footer {
  .pulse::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    background: white;
    border-radius: 100%;
    transform: translate(calc(-50% + 0.25rem), calc(-50% + 0.25rem));
  }
  .pulse.active::after {
    animation: blogFooterPulse 1s ease-out;
  }
}
