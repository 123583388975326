.clg-by-destination-main {
  position: relative;
  .image-card-main {
    .imgZoom {
      width: 100%;
      height: 15.625rem;
      overflow: hidden;
      border-radius: 12px;
      img {
        max-width: 100%;
        transform-origin: center;
        transition: transform 0.4s ease-in-out;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
      }
    }
    &:hover .imgZoom img {
      transform: scale(1.3);
    }
  }
  .mySwiper {
    height: 440px;
  }
}
.clg-by-destination-main .btn-section {
  position: absolute;
  bottom: 35px;
  left: 44%;
}
