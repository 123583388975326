.summary{


font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 21px;
}


.changeinput{
    color: #173CBA;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;

}