.custom-class-next,
.custom-class-prev {
  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.rank-predictor-section {
  background: linear-gradient(to right, #fff0e1, #f5eef5);

  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .custom-class-next {
    width: 44px;
    height: 44px;
    background-color: #113cc0;
    border-radius: 50%;
    svg {
      color: #fff;
      width: 14px;
      height: 12px;
    }
    &:hover,
    &:focus {
      background-color: #231f40;
      border: 1px solid #231f40;
      svg {
        color: #fff;
      }
    }
    right: 240px; /* Adjust the right position as needed */
  }

  .custom-class-prev {
    left: 35px; /* Adjust position of the prev arrow */
    width: 44px;
    height: 44px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
    svg {
      color: #333333;
      width: 14px;
      height: 12px;
    }
    &:hover,
    &:focus {
      background-color: #c4c0c0;
      border: 1px solid #c4c0c0;
      svg {
        color: #333333;
      }
    }
  }

  .slick-slider .slick-list {
    padding: 20px;
    overflow: hidden; /* Ensure no overflow */
  }

  .slick-slider .slick-track {
    display: flex;
    gap: 7rem;
    padding: 3px;

  }

  /* Apply a smooth scaling effect on the current slide */
  .slick-slider {
    .slick-slide {
      
      transition: transform 0.9s ease-in-out, opacity 0.9s ease-in-out;
      transform: scale(0.7); /* Default scale for all slides */
      will-change: transform; /* Trigger GPU acceleration for smoother transitions */
    }

    .slick-slide.slick-current {
      transform: scale(1); 
      z-index: 1;
    }
  }

  /* Target the next slide */
  .slick-slide.slick-active + .slick-slide {
    transform: scale(0.7); /* Scale down the right slide */
  }

  /* Make sure all slides reset properly */
  .slick-slide:not(.slick-current):not(.slick-active) {
    transform: scale(0.7); /* Default scale for non-active slides */
  }

  .rankSlider {
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    transition: transform 0.9s ease-in-out, opacity 0.9s ease-in-out; /* Ensure smooth transitions */

    img {
      transition: opacity 0.9s ease-in-out;
      width: 100%;
    }

    p,
    button {
      transition: opacity 0.9s ease-in-out;
    }
  }
}
