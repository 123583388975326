@media (min-width: 321px) and (max-width: 450px) {
  .takefree-heading img {
    width: 20px;
    height: 20px;
  }
  .takefree-heading h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .takefree-btn button {
    width: 130px;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 12px;
  }
}
