.image-slider {
  position: relative;
  width: 300px; /* Adjust as needed */
  height: 450px; /* Adjust as needed */
  overflow: visible; /* Ensure overflow allows visibility of all cards */
}

/* Static Front Card Styles */
.front-card {
  position: absolute;
  width: 110%;
  height: 110%;
  border-radius: 8px; /* Card-like rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  overflow: hidden; /* Hide overflow for animation effect */
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.front-card-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end; /* Align heading to the bottom */
  padding: 16px; /* Add padding for the heading */
}

.image-card {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 1;
  display: flex;
  align-items: flex-end;
  padding: 16px;
  z-index: var(--zIndex); /* Use the z-index variable */
}

.image-card.stacked {
  transform: translateX(calc(var(--index) * 80px));
}

.image-card.hidden {
  display: none; /* Hide cards that are not active or stacked */
}

.image-card.active {
  transform: translateX(0); /* Front card position */
  z-index: var(--zIndex); /* Highest z-index for active cards */
}

.slider-section {
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Ensure text is centered */
}
.subheading {
  margin-bottom: 2rem; /* Adjust spacing as needed */
  text-align: center; /* Center text */
}

.paragraph {
  color: #333;
  text-align: center; /* Center text */
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
}
.content-container .scholar {
  margin-bottom: 1rem;
  text-align: center; /* Center text */
}

.content-container p {
  color: #333;
  text-align: center; /* Center text */
}

.content-container .buttons {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
  justify-content: center; /* Center buttons horizontally */
}

.content-container .buttons button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%; /* Rounded buttons */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.content-container .buttons button:hover {
  background-color: #0056b3;
}

.content-container .buttons button.active {
  background-color: #ff7f50; /* Different color for the active button */
  transform: scale(1.1); /* Slightly larger for active button */
}

.cta-button {
  display: inline-flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  width: 2rem; /* Adjust to desired circle size */
  height: 2rem; /* Same as width for a perfect circle */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%; /* Makes the button a circle */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1rem;
  font-weight: bold;
  /* margin-top: 1rem; */
}

.cta-button:hover {
  background-color: #0056b3;
}

.cta-button .arrow {
  display: inline-block;
  transition: transform 0.3s;
}

.cta-button:hover .arrow {
  transform: translateX(5px);
}

.cta-button .arrow::before {
  content: "➔";
  font-size: 1rem;
}

/* New styles for image heading */
.image-heading {
  color: #fff;
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}
