.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.breadcrumb-item:not(.active)::after {
  content: "|";
  padding: 0 0.5rem;
}

.breadcrumb-item a {
  text-decoration: none;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}
