.reviewBy {
  color: #383838;

  text-align: right;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}
